import CONSTANTS from './constants';
import MESSENGER from './messenger';
import ALL_CARDS from './data/cards';
import stripSecrets from './state/stripSecrets';


/**
 * Get a specific card from the list of cards, from its ID.
 */
export function getCard(G, ctx, cardID) {
    return G.cards.find(c => c.id === cardID);
}


/**
 * Get the original card object for a given card.
 */
export function getOriginal(G, ctx, card) {
    return ALL_CARDS[card.original];
}


/**
 * Get the functional behavior of a given card.
 */
export function getBehavior(G, ctx, card) {
    return getOriginal(G, ctx, card).behavior;
}


/**
 * Get the specific behavior of a stack item.
 *
 * If the stack item is a card, return the root behavior.
 * If it's a trigger or an ability, return that specific element's behavior.
 */
export function getItemBehavior(G, ctx, item) {
    const card = getCard(G, ctx, item.cardID);
    const ability = item.ability;
    const trigger = item.trigger;

    let behavior = getBehavior(G, ctx, card);
    if (typeof ability !== 'undefined' && ability !== null) {
        behavior = behavior.abilities[ability];
    }
    if (typeof trigger !== 'undefined' && trigger !== null) {
        behavior = behavior.triggers[trigger];
    }

    return behavior;
}


/**
 * Put a card into a given zone.
 */
export function moveToZone(G, ctx, card, destination) {
    const origin = card.zone;
    const behavior = getBehavior(G, ctx, card);

    card.zone = destination;
    card.lastMovedOn = +new Date();

    // Apply any global modifiers.
    if (behavior && behavior.modifiers) {
        behavior.modifiers.forEach((modifier, index) => {
            if (modifier.zone === destination) {
                if (modifier.type === 'global') {
                    G.globalModifiers.push({
                        sourceID: card.id,
                        modifierIndex: index,
                    });
                }
                else if (modifier.type === 'local') {
                    card.modifiers.push({
                        sourceID: card.id,
                        modifierIndex: index,
                    });
                }
            }
        });
    }

    // Register card triggers.
    MESSENGER.publish(MESSENGER.REGISTER_TRIGGERS, G, ctx, card);

    if (card.type === 'Character') {
        if (destination === CONSTANTS.ZONES.BOARD) {
            // Set marker that card arrived on board this turn.
            card.freshOnBoard = true;

            MESSENGER.publish(MESSENGER.TRIGGER, G, ctx, 'CHARACTER_IS_SUMMONED', { triggeredByID: card.id });
        }

        if (origin === CONSTANTS.ZONES.BOARD && destination !== CONSTANTS.ZONES.BOARD) {
            MESSENGER.publish(MESSENGER.TRIGGER, G, ctx, 'CHARACTER_LEAVES_BOARD', { triggeredByID: card.id });
        }

        if (origin === CONSTANTS.ZONES.BOARD && destination === CONSTANTS.ZONES.VOID) {
            MESSENGER.publish(MESSENGER.TRIGGER, G, ctx, 'CHARACTER_DIES', { triggeredByID: card.id });
        }
    }
    else if (card.type === 'Decree') {
        if (destination === CONSTANTS.ZONES.BOARD) {
            MESSENGER.publish(MESSENGER.TRIGGER, G, ctx, 'DECREE_IS_ADOPTED', { triggeredByID: card.id });
        }
    }

    // Remove any global modifiers.
    if (behavior && behavior.modifiers) {
        behavior.modifiers.forEach((modifier, index) => {
            if (modifier.zone === origin) {
                const modifierID = {
                    sourceID: card.id,
                    modifierIndex: index,
                };
                if (modifier.type === 'global') {
                    G.globalModifiers = G.globalModifiers.filter(mod => mod !== modifierID);
                }
                else if (modifier.type === 'local') {
                    card.modifiers = card.modifiers.filter(mod => mod !== modifierID);
                }
            }
        });
    }

    if (
        origin === CONSTANTS.ZONES.BOARD
        && destination !== CONSTANTS.ZONES.BOARD
    ) {
        // Remove all modifiers from the card.
        card.modifiers = [];

        // Reset card state.
        card.exhausted = false;
        card.freshOnBoard = false;

        // Reset card actions.
        if (card.type === 'Character') {
            const original = getOriginal(G, ctx, card);
            card.actions = original.actions;
        }

        // Find all cards attached to this one and move them to the void.
        const attachments = G.cards.filter(c => c.attachedTo === card.id);
        attachments.forEach(c => moveToZone(G, ctx, c, CONSTANTS.ZONES.VOID));

        // Find all triggers created by this card and remove them.
        G.triggeredAbilities = G.triggeredAbilities.filter(
            triggerID => triggerID.cardID !== card.id
        );

        // If this card was supporting or objecting, remove it.
        ctx.playOrder.forEach(player => {
            const participants = G.conflict.participants[player];
            if (participants.current.includes(card.id)) {
                participants.current = participants.current.filter(id => id !== card.id);
            }
            if (participants.declared.includes(card.id)) {
                participants.declared = participants.declared.filter(id => id !== card.id);
            }
        });
    }
}


/**
 * Check all existing modifiers and remove them if they have reached their end of life.
 */
export function removeEndedModifiers(G, ctx, step) {
    const keepModifier = modifierID => modifierID.removeOn !== step;
    G.globalModifiers = G.globalModifiers.filter(keepModifier);
    G.cards = G.cards.map(card => {
        return {
            ...card,
            modifiers: card.modifiers.filter(keepModifier),
        };
    });
}


/**
 * Return true if a behavior has valid targets.
 *
 * `behavior` can be the behavior of a card, a trigger or an ability.
 */
export function hasValidTargets(G, ctx, card, behavior) {
    let isValid = true;

    if (behavior && behavior.targets && behavior.targets.length) {
        const board = G.cards.filter(c => c.zone === CONSTANTS.ZONES.BOARD);
        isValid = behavior.targets.filter(t => !t.optional).every(target => {
            // There are always players to target.
            if (target.types.includes('Player')) {
                return true;
            }

            return target.types.some(type => {
                let boardPotentials = board.filter(c => c.type === type);
                if (target.condition) {
                    boardPotentials = boardPotentials.filter(c => (
                        target.condition(G, ctx, {
                            item: { cardID: card.id },
                            targetID: c.id,
                        })
                    ));
                }

                let stackPotentials = G.stack.items.filter(i => i.type === type);
                if (target.condition) {
                    stackPotentials = stackPotentials.filter(i => (
                        target.condition(G, ctx, {
                            item: { cardID: card.id },
                            targetID: i.id,
                        })
                    ));
                }

                return boardPotentials.length || stackPotentials.length;
            });
        });
    }

    return isValid;
}


/**
 * Return true if the given ability can be played by its controller.
 */

export function canPlayAbility(G, ctx, card, ability) {
    const energy = G.properties[card.controller].energy.available;

    // Does the card have enough actions?
    if (typeof(ability.cost.actions) !== 'undefined') {
        if (
            card.exhausted
            || ability.cost.actions > card.actions
        ) {
            return false;
        }
    }

    if (typeof(ability.cost.energy) !== 'undefined') {
        if (
            ability.cost.energy > energy
        ) {
            return false;
        }
    }

    return hasValidTargets(G, ctx, card, ability);
}


/**
 * Return true if the player is going to propose / adopt their last Decree.
 */
export function isLastDecree(G, ctx, playerID) {
    return G.cards.filter(c => (
        c.type === 'Decree'
        && c.controller === playerID
        && c.zone === CONSTANTS.ZONES.BOARD
    )).length === CONSTANTS.ADOPTED_DECREES_COUNT_TO_WIN - 1;
}


/**
 * Return a copy of a given state (`G`), striped of all secrets.
 */
export function getStripedState(G, ctx) {
    return stripSecrets(JSON.parse(JSON.stringify(G)), ctx);
}


/**
 * Return true if a card is currently in its controller's assembly.
 */
export function inAssembly(G, ctx, card) {
    const participants = G.conflict.participants[card.controller];
    return (
        participants.declared.includes(card.id)
        || participants.current.includes(card.id)
    );
}


/**
 * Return true if a card is currently the first element of its controller's assembly.
 */
export function isPrime(G, ctx, card) {
    const participants = G.conflict.participants[card.controller];
    if (participants.declared.length) {
        const prime = getCard(G, ctx, participants.declared[0]);
        return prime.id === card.id;
    }
    else if (participants.current.length) {
        const prime = getCard(G, ctx, participants.current[0]);
        return prime.id === card.id;
    }
    return false;
}
