import CONSTANTS from '../constants';
import MESSENGER from '../messenger';
import { isLastDecree, getCard, moveToZone } from '../utils';

import steps from '../modules/steps';


/**
 * Propose a decree to be adopted. Starts a conflict.
 */
export default function proposeDecree(G, ctx) {
    if (
        // The player already proposed a decree this turn.
        G.conflict.proposedThisTurn
    ) {
        return;
    }

    G.conflict.proposedThisTurn = true;

    let decree;

    if (isLastDecree(G, ctx, ctx.playerID)) {
        decree = G.cards.find(c => (
            c.owner === ctx.playerID
            && c.type === 'Decree'
            && c.zone === null
        ));
    }
    else {
        const decreeID = G.secret[ctx.playerID].decreeDeck.shift();
        decree = getCard(G, ctx, decreeID);
    }

    // Move the decree to the conflict zone.
    moveToZone(G, ctx, decree, CONSTANTS.ZONES.CONFLICT);
    decree.revealed = true;

    MESSENGER.publish(MESSENGER.TRIGGER, G, ctx, 'DECREE_IS_PROPOSED', { triggeredByID: decree.id });

    steps.set(G, ctx, steps.STEPS.CONFLICT);
}
