import CONSTANTS from '../constants';
import { getCard } from '../utils';


/**
 * Add or remove a Character to/from its controller's participants list.
 */
export default function toggleCharacter(G, ctx, cardID) {
    const card = getCard(G, ctx, cardID);
    const participants = G.conflict.participants[ctx.playerID];

    // Look for card in supporters list.
    const index = participants.current.findIndex(c => c === card.id);

    // If card is not already participating, add it as a participant.
    if (index === -1) {
        if (
            // Verify card belongs to the player.
            card.controller !== ctx.playerID

            // Verify card is on the board.
            || card.zone !== CONSTANTS.ZONES.BOARD

            // Verify card is a Character.
            || card.type !== 'Character'

            // Verify card has enough actions.
            || card.actions <= 0

            // Verify card can perform an action.
            || card.exhausted

            // Verify card isn't already in participants.
            || participants.declared.includes(card.id)

            // If the player is the supporter,
            // verify that the card was on the board at the beginning of the turn.
            || (ctx.playerID === ctx.currentPlayer && card.freshOnBoard)
        ) {
            return;
        }

        // Add card to the list of supporters.
        participants.current.push(card.id);
    }
    else {
        // Remove card from the list of supporters.
        participants.current.splice(index, 1);
    }
}
