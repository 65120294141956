import React from 'react';

import Energy from './Energy';


const energyParser = {
    rule: /(:\d+)/,
    tag: x => {
        const value = parseInt(x.slice(1));
        return <Energy value={ value } />;
    },
};

export default energyParser;
