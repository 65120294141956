import cast from '../modules/cast';
import stack from '../modules/stack';
import targets from '../modules/targets';

import CONSTANTS from '../constants';
import { getBehavior, getCard, hasValidTargets } from '../utils';

/**
 * Play a card from a player's hand.
 */
export default function playCard(G, ctx, cardID) {
    const card = getCard(G, ctx, cardID);
    const behavior = getBehavior(G, ctx, card);

    if (
        // Verify card belongs to player.
        card.owner !== ctx.playerID

        // Verify card is in player's hand.
        || card.zone !== CONSTANTS.ZONES.HAND

        // Verify player has enough energy.
        || G.properties[ctx.playerID].energy.available < card.cost

        // Verify card has valid targets.
        || !hasValidTargets(G, ctx, card, behavior)
    ) {
        return;
    }

    // If card has targets, set them, otherwise directly cast the card.
    const item = stack.createCardItem(G, ctx, card);
    if (behavior && behavior.targets && behavior.targets.length) {
        targets.startTargeting(G, ctx, item);
    }
    else {
        cast.castElement(G, ctx, item);
    }
}
