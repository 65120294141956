import React from 'react';
import { useEffectListener } from 'bgio-effects/react';

import './ConflictSummary.css';

import CONSTANTS from '../constants';
import { getCard, getItemBehavior } from '../utils';
import steps from '../modules/steps';

import BoostCard from './BoostCard';
import CardOnBoard from './CardOnBoard';
import CardWithZoom from '../ui/CardWithZoom';


const BOOST_CARD = {
    prestige: 0,
};


/**
 * Return a card if the id is valid, or a fake boost card otherwise.
 */
function findCard(G, ctx, id) {
    return getCard(G, ctx, id) || BOOST_CARD;
}


/**
 * Reducer that returns the sum of the prestige or boost of a card.
 *
 * Add a card's boost if it's in the conflict zone, add its prestige otherwise.
 */
function computePrestige(acc, card) {
    const value = (card.zone === CONSTANTS.ZONES.CONFLICT) ? card.boost : card.prestige;
    return acc + Math.max(value, 0);
}


/**
 * Return the list of all cards participating in a conflict for a given player.
 */
function getParticipants(G, ctx, player) {
    const everyone = G.conflict.participants[player];
    const participants = everyone.declared.concat(everyone.current);
    return participants.map(id => findCard(G, ctx, id))
}


/**
 * Return a summary of a player's conflict stats.
 */
function getSummary(G, ctx, player) {
    const participants = getParticipants(G, ctx, player);
    return {
        participants,
        influence: G.properties[player].influence,
        prestige: participants.reduce(computePrestige, 0),
    };
}


export default function ConflictSummary(props) {
    const { G, ctx, isActive, moves, playerID, setShowCardInspector, showTargetsForItem } = props;
    const stage = ctx.activePlayers && ctx.activePlayers[playerID];
    const inConflictPhase = steps.isInConflictPhase(G, ctx);

    // Handle the conflict resolution animations.
    const [ conflictResult, setConflictResult ] = React.useState(null);

    useEffectListener(
        'winConflict',
        () => {
            setConflictResult('won');
        },
        [ setConflictResult ]
    );

    useEffectListener(
        'loseConflict',
        () => {
            setConflictResult('lost');
        },
        [ setConflictResult ]
    );

    useEffectListener(
        'abandonConflict',
        () => {
            setConflictResult('abandoned');
        },
        [ setConflictResult ]
    );

    React.useEffect(() => {
        if (!inConflictPhase) {
            if (conflictResult) {
                setConflictResult(null);
            }
        }
    }, [inConflictPhase, conflictResult, setConflictResult]);

    // Handle the boost reveal animations.
    const [ boostCards, setBoostCards ] = React.useState([]);

    useEffectListener(
        'conflictRevealBoosts',
        ({ cards }) => {
            setBoostCards(cards);
        },
        [ setBoostCards ]
    );

    React.useEffect(() => {
        if (!inConflictPhase && boostCards.length) {
            setBoostCards([]);
        }
    }, [inConflictPhase, boostCards, setBoostCards]);

    const decree = G.cards.find(c => c.type === 'Decree' && c.zone === CONSTANTS.ZONES.CONFLICT);
    if (!inConflictPhase || !decree) {
        return <section />;
    }

    const objectingPlayer = ctx.playOrder.find(p => p !== ctx.currentPlayer);

    const summaries = {};
    ctx.playOrder.forEach(player => {
        summaries[player] = getSummary(G, ctx, player);
    });

    let total = (
        summaries[ctx.currentPlayer].influence
        + summaries[ctx.currentPlayer].prestige
        - summaries[objectingPlayer].influence
        - summaries[objectingPlayer].prestige
    );
    if (summaries[ctx.currentPlayer].participants.includes(BOOST_CARD)) {
        total += ' + ?';
    }
    if (summaries[objectingPlayer].participants.includes(BOOST_CARD)) {
        total += ' - ?';
    }

    // Class of the total number, controls the color of that element.
    let totalCls = 'failure';
    if (typeof total !== 'number') {
        totalCls = 'uncertain';
    }
    else if (total >= decree.influence) {
        totalCls = 'success';
    }

    function inspectCard(event, card) {
        event.preventDefault();
        setShowCardInspector(card.id);
    }

    // If targeting, find the next target criteria to highlight cards if appropriate.
    let nextTarget = null;
    if (stage === 'target') {
        const item = G.targets.currentItem;
        const behavior = getItemBehavior(G, ctx, item);
        nextTarget = behavior.targets[item.targets.length];
    }

    function getCards(player) {
        const board = [ ...(summaries[player].participants) ];
        const cardIDs = board.map(c => c.id);
        const soulshifts = G.cards.filter(c => (
            c.zone === CONSTANTS.ZONES.BOARD
            && cardIDs.includes(c.attachedTo)
        ));

        // The order of cards is reversed because we want to show the "Prime"
        // to the right, closest to the Decree.
        return board.reverse().map(
            (card, index) => {
                const boostCard = boostCards.find(c => c.id === card.id);
                if (boostCard || card === BOOST_CARD) {
                    return <BoostCard
                        card={ boostCard }
                        index={ index }
                        board={ board }
                        owner={ player }
                        playerID={ playerID }
                        key='boost-card'
                    />;
                }
                return <CardOnBoard
                    G={ G }
                    ctx={ ctx }
                    card={ card }
                    index={ index }
                    isActive={ isActive }
                    playerID={ playerID }
                    moves={ moves }
                    owner={ player }
                    board={ board }
                    zone='conflict'
                    nextTarget={ nextTarget }
                    soulshifts={ soulshifts }
                    inspectCard={ inspectCard }
                    showTargetsForItem={ showTargetsForItem }
                    key={ card.id }
                />;
            }
        );
    }

    const opponentID = ctx.playOrder.find(p => p !== playerID);
    const opponentCards = getCards(opponentID);
    const playerCards = getCards(playerID);

    let decreeCls = 'decree';
    if (conflictResult) {
        decreeCls += ' ' + conflictResult;
    }

    return (
        <section className='conflict-summary'>
            { !conflictResult ? null :
            <div className='result'>
                { conflictResult === 'won' ? <p>Adopted</p> : null }
                { conflictResult === 'lost' ? <p>Rejected</p> : null }
                { conflictResult === 'abandoned' ? <p>Abandoned</p> : null }
            </div>
            }
            <ol className='supporters opponent'>
                { opponentCards }
            </ol>
            <ol className='supporters you'>
                { playerCards }
            </ol>
            <div className='objective'>
                <p className={ totalCls }>{ total }</p>
                <div className={ decreeCls }>
                    <CardWithZoom
                        card={ decree }
                        size='small'
                        zoomPosition='left middle'
                        animation='slit-in'
                    />
                </div>
            </div>
        </section>
    );
}
