import React from 'react';
import { useEffectListener } from 'bgio-effects/react';

import SoulsBoard from './board/SoulsBoard';


function update(G, tmpState) {
    const cards = [ ...(tmpState.cards) ];
    G.cards.forEach(card => {
        // Find the card in the new list of cards.
        const cardIndex = cards.findIndex(c => c.id === card.id);

        // If it doesn't exist, add it.
        if (cardIndex < 0) {
            cards.push(card);
        }
    });
    return {
        ...G,
        ...(tmpState),
        cards,
    };
}


/**
 * Handle temporary state from animations and replace the actual state with it.
 *
 * This component listens to all animations effects, and whenever one sends
 * a temporary state update (`payload.G`), it replaces the actual state with
 * a merge of the current state and the temporary state.
 *
 * This allows to show animations with the state as it should be at the time
 * of that animation. It is needed because there can be a lot of state updates
 * between the moment the effect / animation is declared and the end of the
 * move, when the resulting state is sent be the framework.
 */
export default function AnimationStateHandler({ G, ctx, ...props }) {
    const [ animating, setAnimating ] = React.useState(false);

    // In order to avoid re-rendering the game a lot when an animation is happening (because of
    // frequent changes to `G`), we need to use a reducer.
    function reducer(state, action) {
        switch (action.type) {
            case 'update':
                return update(G, action.state);
            case 'reset':
                return null;
            default:
                return state;
        }
    }

    const [ state, dispatch ] = React.useReducer(reducer, null);

    useEffectListener(
        'effects:start',
        () => {
            setAnimating(true);
        },
        [ setAnimating ]
    );

    useEffectListener(
        '*',
        (effectName, effectPayload) => {
            if (effectPayload && effectPayload.G) {
                dispatch({ type: 'update', state: effectPayload.G });
            }
        },
        [ dispatch ]
    );

    useEffectListener(
        'effects:end',
        () => {
            setAnimating(false);
            dispatch({ type: 'reset' });
        },
        [ setAnimating, dispatch ]
    );

    // This is a trick to consider that players are not in any stages while an animation is going on.
    // It works around some crashes, because even if this updates the state (`G`), it doesn't update
    // the context (`ctx`), which can lead to inconsistencies and bugs.
    const context = { ...ctx };
    if (state) {
        context.activePlayers = {};
    }

    return <SoulsBoard G={ state || G } ctx={ context } animating={ animating } { ...props } />;
}
