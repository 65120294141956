import * as ACTIONS from '../actions';
import CONSTANTS from '../constants';
import {
    getCard,
    inAssembly,
    isPrime,
    moveToZone,
} from '../utils';


const CARDS = [
    // ------------------------ \\
    // -- TOOLS FOR TESTING --- \\
    // ------------------------ \\

    // {
    //     data: {
    //         name: 'Counterspell',
    //         cost: 0,
    //         type: 'Event',
    //         supertypes: ['Instant'],
    //         instant: true,
    //         text: [
    //             'Counter target spell or ability.'
    //         ],
    //         boost: 1,
    //         illus: '',
    //     },
    //     behavior: {
    //         targets: [
    //             {
    //                 types: ['Card'],
    //             },
    //         ],
    //         resolve: (G, ctx, targets, card) => {
    //             ACTIONS.counter(G, ctx, targets[0]);
    //         },
    //     },
    // },

    // {
    //     data: {
    //         name: 'Targets and draw',
    //         cost: 0,
    //         type: 'Event',
    //         supertypes: ['Instant'],
    //         instant: true,
    //         text: [
    //             'Up to three target Characters each gain 1 action.',
    //             'Draw a card.',
    //         ],
    //         boost: 1,
    //         illus: '',
    //     },
    //     behavior: {
    //         targets: [
    //             {
    //                 types: ['Character'],
    //                 optional: true,
    //             },
    //             {
    //                 types: ['Character'],
    //                 optional: true,
    //                 unique: true,
    //             },
    //             {
    //                 types: ['Character'],
    //                 optional: true,
    //                 unique: true,
    //             },
    //         ],
    //         resolve: (G, ctx, targets, card) => {
    //             targets.forEach(targetID => {
    //                 const target = getCard(G, ctx, targetID);
    //                 ACTIONS.gainActions(G, ctx, target, 1);
    //             });
    //             ACTIONS.draw(G, ctx, card.controller, 1);
    //         },
    //     },
    // },

    // {
    //     data: {
    //         cost: 0,
    //         name: 'Decree Simulator',
    //         type: 'Character',
    //         prestige: 2,
    //         actions: 2,
    //         boost: 2,
    //         text: [
    //         ],
    //     },
    //     behavior: {
    //     },
    // },

    // ------------------------ \\
    // ------ CHARACTERS ------ \\
    // ------------------------ \\

    {
        key: 'XXX-C01',
        data: {
            name: 'Inspiring Flute Player',
            cost: 1,
            type: 'Character',
            text: [
                ':2, :action: :instant — Another target Character gains 2 actions.'
            ],
            boost: 1,
            prestige: 1,
            actions: 2,
            illus: 'flute-player',
        },
        behavior: {
            abilities: [
                {
                    instant: true,
                    cost: {
                        energy: 2,
                        actions: 1,
                    },
                    targets: [
                        {
                            types: ['Character'],
                            condition: (G, ctx, params) => params.item.cardID !== params.targetID,
                        },
                    ],
                    resolve: (G, ctx, targets, card) => {
                        const target = getCard(G, ctx, targets[0]);
                        if (target) {
                            ACTIONS.gainActions(G, ctx, target, 2);
                        }
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C02',
        data: {
            name: 'Desperate Geisha',
            cost: 1,
            type: 'Character',
            text: [
                'As long as :~ has no actions, it gains +1 Prestige.',
            ],
            boost: 2,
            prestige: 1,
            actions: 2,
            illus: 'haunted-geisha',
        },
        behavior: {
            modifiers: [
                {
                    type: 'local',
                    zone: CONSTANTS.ZONES.BOARD,
                    effect: (card, source, G, ctx) => {
                        if (card.actions === 0) {
                            return {
                                ...card,
                                prestige: card.prestige + 1,
                            };
                        }

                        return card;
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C03',
        data: {
            name: 'Exploited Peasant',
            cost: 2,
            type: 'Character',
            text: [
                ':death → Draw a card.',
            ],
            boost: 2,
            prestige: 2,
            actions: 1,
            illus: 'exploited-peasant',
        },
        behavior: {
            triggers: [
                {
                    type: 'CHARACTER_DIES',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        ACTIONS.draw(G, ctx, card.controller);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C04',
        data: {
            name: 'Tax Collector',
            cost: 1,
            type: 'Character',
            text: [
                ':prime — :~ gains -1 Prestige.'
            ],
            boost: 2,
            prestige: 2,
            actions: 2,
            illus: 'tax-collector',
        },
        behavior: {
            modifiers: [
                {
                    type: 'local',
                    zone: CONSTANTS.ZONES.BOARD,
                    effect: (card, source, G, ctx) => {
                        if (isPrime(G, ctx, card)) {
                            return {
                                ...card,
                                prestige: card.prestige - 1,
                            };
                        }

                        return card;
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C05',
        data: {
            name: 'Citizen',
            cost: 2,
            type: 'Character',
            text: [],
            boost: 2,
            prestige: 2,
            actions: 2,
            illus: 'citizen',
        },
    },

    {
        key: 'XXX-C06',
        data: {
            name: 'Jean Bernard',
            cost: 2,
            type: 'Character',
            text: [
                ':prime — Whenever you cast an Event, :~ gains +2 Prestige until end of turn.',
            ],
            boost: 2,
            prestige: 3,
            actions: 1,
            illus: 'jean-bernard',
        },
        behavior: {
            modifiers: [
                {
                    type: 'target',
                    effect: card => ({ ...card, prestige: card.prestige + 2 }),
                },
            ],
            triggers: [
                {
                    type: 'EVENT_IS_CAST',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        if (isPrime(G, ctx, card)) {
                            const event = getCard(G, ctx, params.triggeredByID);
                            return card.controller === event.owner;
                        }
                        return false;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        const modifierID = { sourceID: card.id, modifierIndex: 0 };
                        // Make this last only until end of turn.
                        modifierID.removeOn = 'END_OF_TURN_CLEANUP';
                        card.modifiers.push(modifierID);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C07',
        data: {
            name: 'Budding Adventurers',
            cost: 2,
            type: 'Character',
            text: [
                ':summon → Up to two target Characters each gain 1 action.',
            ],
            boost: 3,
            prestige: 2,
            actions: 0,
            illus: 'budding-adventurers',
        },
        behavior: {
            triggers: [
                {
                    type: 'CHARACTER_IS_SUMMONED',
                    zone: CONSTANTS.ZONES.BOARD,
                    targets: [
                        {
                            types: ['Character'],
                            optional: true,
                        },
                        {
                            types: ['Character'],
                            optional: true,
                            unique: true,
                        },
                    ],
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        targets.forEach(cardID => {
                            const card = getCard(G, ctx, cardID);
                            if (card.zone === CONSTANTS.ZONES.BOARD) {
                                ACTIONS.gainActions(G, ctx, card, 1);
                            }
                        });
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C08',
        data: {
            name: 'Dancing Courtesan',
            cost: 2,
            type: 'Character',
            text: [
                'As long as it\'s not your turn, :~ gains +2 Prestige.'
            ],
            boost: 1,
            prestige: 1,
            actions: 2,
            illus: 'dancing-courtesan',
        },
        behavior: {
            modifiers: [
                {
                    type: 'local',
                    zone: CONSTANTS.ZONES.BOARD,
                    effect: (card, source, G, ctx) => {
                        if (ctx.currentPlayer !== card.controller) {
                            return {
                                ...card,
                                prestige: card.prestige + 2,
                            };
                        }

                        return card;
                    }
                }
            ]
        },
    },

    {
        key: 'XXX-C09',
        data: {
            name: 'Artisans',
            cost: 3,
            type: 'Character',
            text: [],
            boost: 2,
            prestige: 3,
            actions: 2,
            illus: 'artisans',
        },
    },

    {
        key: 'XXX-C10',
        data: {
            name: 'Enforcer of the Law',
            cost: 3,
            type: 'Character',
            text: [
                'Whenever a Decree is adopted, :~ gains +1 Prestige.',
            ],
            boost: 1,
            prestige: 2,
            actions: 2,
            illus: 'enforcer-law',
        },
        behavior: {
            modifiers: [
                {
                    type: 'target',
                    effect: card => ({ ...card, prestige: card.prestige + 1 }),
                },
            ],
            triggers: [
                {
                    type: 'DECREE_IS_ADOPTED',
                    zone: CONSTANTS.ZONES.BOARD,
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        const modifierID = { sourceID: card.id, modifierIndex: 0 };
                        card.modifiers.push(modifierID);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C11',
        data: {
            name: 'Fanatical Savior',
            cost: 3,
            type: 'Character',
            supertypes: ['Instant'],
            text: [
                ':summon → Target Character gains +2 Prestige until end of turn.',
            ],
            boost: 2,
            prestige: 2,
            actions: 1,
            illus: 'fanatical-savior',
            instant: true,
        },
        behavior: {
            modifiers: [
                {
                    type: 'target',
                    effect: card => ({ ...card, prestige: card.prestige + 2 }),
                },
            ],
            triggers: [
                {
                    type: 'CHARACTER_IS_SUMMONED',
                    zone: CONSTANTS.ZONES.BOARD,
                    targets: [
                        {
                            types: ['Character'],
                        },
                    ],
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const target = getCard(G, ctx, targets[0]);

                        const modifierID = { sourceID: params.cardID, modifierIndex: 0 };
                        // Make this last only until end of turn.
                        modifierID.removeOn = 'END_OF_TURN_CLEANUP';
                        target.modifiers.push(modifierID);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C12',
        data: {
            name: 'Enthralled Traveler',
            cost: 3,
            type: 'Character',
            text: [
                ':summon → If you adopted a Decree this turn, draw a card.'
            ],
            boost: 3,
            prestige: 2,
            actions: 3,
            illus: 'patient-diplomat',
        },
        behavior: {
            triggers: [
                {
                    type: 'CHARACTER_IS_SUMMONED',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        if (G.conflict.adoptedThisTurn) {
                            const card = getCard(G, ctx, params.cardID);
                            ACTIONS.draw(G, ctx, card.controller);
                        }
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C13',
        data: {
            name: 'Activist',
            cost: 4,
            type: 'Character',
            text: [
                ':prime — Other Characters in your assembly gain +1 Prestige.',
            ],
            boost: 3,
            prestige: 1,
            actions: 3,
            illus: 'activist',
        },
        behavior: {
            modifiers: [
                {
                    type: 'global',
                    zone: CONSTANTS.ZONES.BOARD,
                    effect: (card, source, G, ctx) => {
                        if (
                            isPrime(G, ctx, source)
                            && card.type === 'Character'
                            && card.zone === CONSTANTS.ZONES.BOARD
                            && card.controller === source.controller
                            && card.id !== source.id
                            && inAssembly(G, ctx, card)
                        ) {
                            return { ...card, prestige: card.prestige + 1 };
                        }

                        return card;
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C14',
        data: {
            name: 'Focused Philosopher',
            cost: 4,
            type: 'Character',
            text: [
                ':splendor 1 (When Focused Philosopher enters the board, you gain 1 influence. When it leaves the board, you lose 1 influence.)'
            ],
            boost: 1,
            prestige: 2,
            actions: 2,
            illus: 'focused-philosopher',
        },
        behavior: {
            triggers: [
                {
                    type: 'CHARACTER_IS_SUMMONED',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        ACTIONS.gainInfluence(G, ctx, card.controller, 1);
                    },
                },
                {
                    type: 'CHARACTER_LEAVES_BOARD',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        ACTIONS.loseInfluence(G, ctx, card.controller, 1);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C15',
        data: {
            name: 'Augur of Rainstorms',
            cost: 4,
            type: 'Character',
            text: [
                ':summon → Return target Character to its owner\'s hand.',
            ],
            boost: 2,
            prestige: 1,
            actions: 2,
            illus: 'augur-rainstorm',
        },
        behavior: {
            triggers: [
                {
                    type: 'CHARACTER_IS_SUMMONED',
                    zone: CONSTANTS.ZONES.BOARD,
                    targets: [
                        {
                            types: ['Character'],
                        },
                    ],
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const target = getCard(G, ctx, targets[0]);

                        // Verify card is on the board.
                        if (target.zone !== CONSTANTS.ZONES.BOARD) {
                            return;
                        }

                        moveToZone(G, ctx, target, CONSTANTS.ZONES.HAND);
                        target.revealed = true;
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C16',
        data: {
            name: 'Mayor',
            cost: 5,
            type: 'Character',
            text: [
                ':prime — :~ gains +2 Prestige.'
            ],
            boost: 2,
            prestige: 2,
            actions: 3,
            illus: 'mayor',
        },
        behavior: {
            modifiers: [
                {
                    type: 'local',
                    zone: CONSTANTS.ZONES.BOARD,
                    effect: (card, source, G, ctx) => {
                        if (isPrime(G, ctx, card)) {
                            return {
                                ...card,
                                prestige: card.prestige + 2,
                            };
                        }

                        return card;
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C17',
        data: {
            name: 'Ravenous Haunter',
            cost: 5,
            type: 'Character',
            text: [
                ':summon → Destroy another target Character.',
            ],
            boost: 3,
            prestige: 2,
            actions: 2,
            illus: 'ravenous-haunter',
        },
        behavior: {
            triggers: [
                {
                    type: 'CHARACTER_IS_SUMMONED',
                    zone: CONSTANTS.ZONES.BOARD,
                    targets: [
                        {
                            types: ['Character'],
                            condition: (G, ctx, params) => params.item.cardID !== params.targetID,
                        },
                    ],
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        ACTIONS.destroy(G, ctx, getCard(G, ctx, targets[0]));
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C18',
        data: {
            name: 'Ostentatious Aristocrat',
            cost: 6,
            type: 'Character',
            text: [
                ':action: Up to two target Characters each lose 1 action.',
            ],
            boost: 3,
            prestige: 3,
            actions: 3,
            illus: 'aristocrats',
        },
        behavior: {
            abilities: [
                {
                    cost: {
                        actions: 1,
                    },
                    targets: [
                        {
                            types: ['Character'],
                            optional: true,
                        },
                        {
                            types: ['Character'],
                            optional: true,
                            unique: true,
                        },
                    ],
                    resolve: (G, ctx, targets, card) => {
                        targets.forEach(cardID => {
                            ACTIONS.loseActions(G, ctx, getCard(G, ctx, cardID), 1);
                        });
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C19',
        data: {
            cost: 6,
            name: 'Governor',
            type: 'Character',
            text: [
                ':splendor 2 (When Governor enters the board, you gain 2 influence. When it leaves the board, you lose 2 influence.)',
            ],
            boost: 2,
            prestige: 3,
            actions: 3,
            illus: 'governor',
        },
        behavior: {
            triggers: [
                {
                    type: 'CHARACTER_IS_SUMMONED',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        ACTIONS.gainInfluence(G, ctx, card.controller, 2);
                    },
                },
                {
                    type: 'CHARACTER_LEAVES_BOARD',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        ACTIONS.loseInfluence(G, ctx, card.controller, 2);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C20',
        data: {
            name: 'Great General Barei',
            cost: 7,
            type: 'Character',
            text: [
                ':summon → Exhaust all Characters your opponents control.',
            ],
            boost: 1,
            prestige: 6,
            actions: 3,
            illus: 'great-general-barei',
        },
        behavior: {
            triggers: [
                {
                    type: 'CHARACTER_IS_SUMMONED',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        G.cards.filter(c => (
                            c.controller !== card.controller
                            && c.zone === CONSTANTS.ZONES.BOARD
                            && c.type === 'Character'
                        )).forEach(c => c.exhausted = true);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C21',
        data: {
            name: 'Paradise Satyr',
            cost: 4,
            type: 'Character',
            text: [
                ':action:action: Target Character loses all actions.',
            ],
            boost: 3,
            prestige: 2,
            actions: 2,
            illus: 'paradise-satyr',
        },
        behavior: {
            abilities: [
                {
                    cost: {
                        actions: 2,
                    },
                    targets: [
                        {
                            types: ['Character'],
                        },
                    ],
                    resolve: (G, ctx, targets, card) => {
                        const target = getCard(G, ctx, targets[0]);
                        if (target) {
                            ACTIONS.loseActions(G, ctx, target, target.actions);
                        }
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C22',
        data: {
            name: 'Protector of the Weak',
            cost: 3,
            type: 'Character',
            text: [
                ':1, :action: Return another target Character you control to its owner\'s hand.',
            ],
            boost: 1,
            prestige: 1,
            actions: 3,
            illus: 'protector-weak',
        },
        behavior: {
            abilities: [
                {
                    cost: {
                        actions: 1,
                        energy: 1,
                    },
                    targets: [
                        {
                            types: ['Character'],
                            condition: (G, ctx, params) => params.item.cardID !== params.targetID,
                        },
                    ],
                    resolve: (G, ctx, targets, card) => {
                        const target = getCard(G, ctx, targets[0]);
                        if (target) {
                            moveToZone(G, ctx, target, CONSTANTS.ZONES.HAND);
                        }
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C23',
        data: {
            name: 'Meditating Monk',
            cost: 1,
            type: 'Character',
            text: [
                ':action: :instant — :~ gains +1 Prestige.'
            ],
            boost: 1,
            prestige: 1,
            actions: 2,
            illus: 'meditating-monk',
        },
        behavior: {
            modifiers: [
                {
                    type: 'target',
                    effect: card => ({ ...card, prestige: card.prestige + 1 }),
                },
            ],
            abilities: [
                {
                    instant: true,
                    cost: {
                        actions: 1,
                    },
                    resolve: (G, ctx, targets, card) => {
                        if (card.zone === CONSTANTS.ZONES.BOARD) {
                            const modifierID = { sourceID: card.id, modifierIndex: 0 };
                            card.modifiers.push(modifierID);
                        }
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C24',
        data: {
            name: 'Oni of the Bridge',
            cost: 2,
            type: 'Character',
            text: [
                'Whenever you increase your energy pool, :~ gains 1 action.'
            ],
            boost: 1,
            prestige: 3,
            actions: 0,
            illus: 'oni-bridge',
        },
        behavior: {
            triggers: [
                {
                    type: 'INCREASE_ENERGY_POOL',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        return params.playerID === card.controller;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        if (card.zone === CONSTANTS.ZONES.BOARD) {
                            ACTIONS.gainActions(G, ctx, card, 1);
                        }
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C25',
        data: {
            name: 'Cursed Hunter',
            cost: 3,
            type: 'Character',
            text: [
                ':death → Increase your energy pool by 1.',
            ],
            boost: 2,
            prestige: 2,
            actions: 2,
            illus: 'cursed-hunter',
        },
        behavior: {
            triggers: [
                {
                    type: 'CHARACTER_DIES',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        ACTIONS.gainEnergy(G, ctx, card.controller, 1);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C26',
        data: {
            name: 'Tavern\'s Fool',
            cost: 2,
            type: 'Character',
            text: [
                ':action:action: Your opponent loses 1 influence.',
            ],
            boost: 3,
            prestige: 1,
            actions: 2,
            illus: 'tavern-fool',
        },
        behavior: {
            abilities: [
                {
                    cost: {
                        actions: 2,
                    },
                    resolve: (G, ctx, targets, card) => {
                        const opponent = ctx.playOrder.find(p => p !== card.controller);
                        ACTIONS.loseInfluence(G, ctx, opponent, 1);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C27',
        data: {
            name: 'Team of Archeologists',
            cost: 5,
            type: 'Character',
            text: [
                ':summon → Draw a random card from your void.',
            ],
            boost: 1,
            prestige: 3,
            actions: 2,
            illus: 'team-archeologists',
        },
        behavior: {
            triggers: [
                {
                    type: 'CHARACTER_IS_SUMMONED',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        const void_ = G.cards.filter(c => (
                            c.owner === card.controller
                            && c.zone === CONSTANTS.ZONES.VOID
                        ));

                        if (void_.length > 0) {
                            const index = ctx.random.Die(void_.length) - 1;
                            moveToZone(G, ctx, void_[index], CONSTANTS.ZONES.HAND);
                        }
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-C28',
        data: {
            name: 'Arrogant Leader',
            cost: 4,
            type: 'Character',
            text: [
                ':summon → Other Characters you control lose 1 action.',
            ],
            boost: 2,
            prestige: 5,
            actions: 2,
            illus: 'arrogant-leader',
        },
        behavior: {
            triggers: [
                {
                    type: 'CHARACTER_IS_SUMMONED',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        const otherCharacters = G.cards.filter(c => (
                            c.type === 'Character'
                            && c.controller === card.controller
                            && c.zone === CONSTANTS.ZONES.BOARD
                            && c.id !== card.id
                        ));

                        otherCharacters.forEach(
                            c => ACTIONS.loseActions(G, ctx, c, 1)
                        );
                    },
                },
            ],
        },
    },

    // ------------------------ \\
    // -------- EVENTS -------- \\
    // ------------------------ \\

    {
        key: 'XXX-E01',
        data: {
            name: 'Divine Assistance',
            cost: 1,
            type: 'Event',
            supertypes: ['Instant'],
            instant: true,
            text: [
                'Target Character gains +2 Prestige until end of turn.',
            ],
            boost: 1,
            illus: 'divine-assistance',
        },
        behavior: {
            modifiers: [
                {
                    type: 'target',
                    effect: card => ({ ...card, prestige: card.prestige + 2 }),
                },
            ],
            targets: [
                {
                    types: ['Character'],
                },
            ],
            resolve: (G, ctx, targets, card) => {
                const target = getCard(G, ctx, targets[0]);

                const modifierID = { sourceID: card.id, modifierIndex: 0 };
                // Make this last only until end of turn.
                modifierID.removeOn = 'END_OF_TURN_CLEANUP';
                target.modifiers.push(modifierID);
            },
        },
    },

    {
        key: 'XXX-E02',
        data: {
            name: 'Sleep Paralysis',
            cost: 1,
            type: 'Event',
            text: [
                'Target Character loses 1 action.',
            ],
            boost: 2,
            illus: 'sleep-paralysis',
        },
        behavior: {
            targets: [
                {
                    types: ['Character'],
                },
            ],
            resolve: (G, ctx, targets, card) => {
                const target = getCard(G, ctx, targets[0]);
                ACTIONS.loseActions(G, ctx, target, 1);
            },
        },
    },

    {
        key: 'XXX-E03',
        data: {
            name: 'Fatal Wound',
            cost: 2,
            type: 'Event',
            text: [
                'Target Character loses 2 actions.',
            ],
            boost: 3,
            illus: 'fatal-wound',
        },
        behavior: {
            targets: [
                {
                    types: ['Character'],
                },
            ],
            resolve: (G, ctx, targets, card) => {
                const target = getCard(G, ctx, targets[0]);
                ACTIONS.loseActions(G, ctx, target, 2);
            },
        },
    },

    {
        key: 'XXX-E04',
        data: {
            name: 'Irresistible Distraction',
            cost: 2,
            type: 'Event',
            supertypes: ['Instant'],
            instant: true,
            text: [
                'Return target Character to its owner\'s hand.',
            ],
            boost: 2,
            illus: 'irresistible-distraction',
        },
        behavior: {
            targets: [
                {
                    types: ['Character'],
                },
            ],
            resolve: (G, ctx, targets, card) => {
                const target = getCard(G, ctx, targets[0]);
                if (target) {
                    // Verify card is on the board.
                    if (target.zone !== CONSTANTS.ZONES.BOARD) {
                        return;
                    }

                    moveToZone(G, ctx, target, CONSTANTS.ZONES.HAND);
                    target.revealed = true;
                }
            },
        },
    },

    {
        key: 'XXX-E05',
        data: {
            name: 'Divination',
            cost: 3,
            type: 'Event',
            text: [
                'Draw two cards.',
                ':boost → :prime — Draw a card.',
            ],
            boost: 1,
            illus: 'divination',
        },
        behavior: {
            resolve: (G, ctx, targets, card) => {
                ACTIONS.draw(G, ctx, card.controller, 2);
            },
            triggers: [
                {
                    type: 'BOOST_WITH_CARD',
                    zone: CONSTANTS.ZONES.CONFLICT,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        if (isPrime(G, ctx, card)) {
                            ACTIONS.draw(G, ctx, card.controller, 1);
                        }
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-E06',
        data: {
            name: 'Murder',
            cost: 4,
            type: 'Event',
            supertypes: ['Instant'],
            instant: true,
            text: [
                'Destroy target Character.',
                ':boost :prime — This gains +1 Boost for each Character in your Assembly.',
                ':boost → You lose 1 influence.',
            ],
            boost: 0,
            illus: 'murder',
        },
        behavior: {
            targets: [
                {
                    types: ['Character'],
                },
            ],
            resolve: (G, ctx, targets, card) => {
                ACTIONS.destroy(G, ctx, getCard(G, ctx, targets[0]));
            },
            triggers: [
                {
                    type: 'BOOST_WITH_CARD',
                    zone: CONSTANTS.ZONES.CONFLICT,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        ACTIONS.loseInfluence(G, ctx, card.controller, 1);
                    },
                },
            ],
            modifiers: [
                {
                    type: 'local',
                    zone: CONSTANTS.ZONES.CONFLICT,
                    effect: (card, source, G, ctx) => {
                        if (isPrime(G, ctx, card)) {
                            const participants = G.conflict.participants[card.controller];

                            const isCharacter = cardID => getCard(G, ctx, cardID).type === 'Character';

                            let boost = card.boost;
                            boost += participants.declared.filter(isCharacter).length;
                            boost += participants.current.filter(isCharacter).length;

                            return {
                                ...card,
                                boost,
                            };
                        }

                        return card;
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-E07',
        data: {
            name: 'Meeting with Death',
            cost: 6,
            type: 'Event',
            text: [
                'Destroy up to two target Characters.',
                'You lose 1 influence.',
            ],
            boost: 4,
            illus: 'meeting-death',
        },
        behavior: {
            targets: [
                {
                    types: ['Character'],
                    optional: true,
                },
                {
                    types: ['Character'],
                    optional: true,
                    unique: true,
                },
            ],
            resolve: (G, ctx, targets, card) => {
                targets.forEach(cardID => {
                    ACTIONS.destroy(G, ctx, getCard(G, ctx, cardID));
                });
                ACTIONS.loseInfluence(G, ctx, card.controller, 1);
            },
        },
    },

    {
        key: 'XXX-E08',
        data: {
            name: 'Religious Zeal',
            cost: 3,
            type: 'Event',
            supertypes: ['Instant'],
            instant: true,
            text: [
                'Characters you control gain +1 Prestige until end of turn.',
            ],
            boost: 2,
            illus: 'religious-zeal',
        },
        behavior: {
            modifiers: [
                {
                    type: 'target',
                    effect: card => ({ ...card, prestige: card.prestige + 1 }),
                },
            ],
            resolve: (G, ctx, targets, card) => {
                const characters = G.cards.filter(c => (
                    c.controller === card.controller
                    && c.type === 'Character'
                    && c.zone === CONSTANTS.ZONES.BOARD
                ));

                const modifierID = { sourceID: card.id, modifierIndex: 0 };
                // Make this last only until end of turn.
                modifierID.removeOn = 'END_OF_TURN_CLEANUP';
                characters.forEach(c => c.modifiers.push(modifierID));
            },
        },
    },

    {
        key: 'XXX-E09',
        data: {
            name: 'Wrath of Dog',
            cost: 5,
            type: 'Event',
            text: [
                'Destroy all Characters.',
            ],
            boost: 1,
            illus: 'wrath-of-dog',
        },
        behavior: {
            resolve: (G, ctx, targets, card) => {
                const characters = G.cards.filter(c => (
                    c.type === 'Character'
                    && c.zone === CONSTANTS.ZONES.BOARD
                ));

                characters.forEach(c => ACTIONS.destroy(G, ctx, c));
            },
        },
    },

    {
        key: 'XXX-E10',
        data: {
            name: 'Second Wind',
            cost: 3,
            type: 'Event',
            supertypes: ['Instant'],
            instant: true,
            text: [
                'Characters you control with 0 actions gain 1 action.',
            ],
            boost: 2,
            illus: 'second-wind',
        },
        behavior: {
            resolve: (G, ctx, targets, card) => {
                const characters = G.cards.filter(c => (
                    c.type === 'Character'
                    && c.zone === CONSTANTS.ZONES.BOARD
                    && c.controller === card.controller
                    && c.actions === 0
                ));

                characters.forEach(c => ACTIONS.gainActions(G, ctx, c, 1));
            },
        },
    },

    {
        key: 'XXX-E11',
        data: {
            name: 'Work',
            cost: 2,
            type: 'Event',
            text: [
                'Increase your energy pool by 1.',
            ],
            boost: 1,
            illus: 'work',
        },
        behavior: {
            resolve: (G, ctx, targets, card) => {
                ACTIONS.gainEnergy(G, ctx, card.controller, 1);
            },
        },
    },

    {
        key: 'XXX-E12',
        data: {
            name: 'Excommunicate',
            cost: 2,
            type: 'Event',
            supertypes: ['Instant'],
            instant: true,
            text: [
                'Counter target Character card.',
            ],
            boost: 2,
            illus: 'excommunicate',
        },
        behavior: {
            targets: [
                {
                    types: ['Card'],
                    condition: (G, ctx, params) => {
                        const target = G.stack.items.find(i => i.id === params.targetID);
                        if (target && target.type === 'Card') {
                            const card = getCard(G, ctx, target.cardID);
                            if (card.type === 'Character') {
                                return true;
                            }
                        }
                        return false;
                    },
                },
            ],
            resolve: (G, ctx, targets, card) => {
                ACTIONS.counter(G, ctx, targets[0]);
            },
        },
    },

    {
        key: 'XXX-E13',
        data: {
            name: 'Early End',
            cost: 2,
            type: 'Event',
            supertypes: ['Instant'],
            instant: true,
            text: [
                'Destroy target Character with 0 actions.',
            ],
            boost: 1,
            illus: 'early-end',
        },
        behavior: {
            targets: [
                {
                    types: ['Character'],
                    condition: (G, ctx, params) => {
                        const target = getCard(G, ctx, params.targetID);
                        return (target && target.actions === 0);
                    },
                },
            ],
            resolve: (G, ctx, targets, card) => {
                const target = getCard(G, ctx, targets[0]);
                ACTIONS.destroy(G, ctx, target);
            },
        },
    },

    {
        key: 'XXX-E14',
        data: {
            name: 'Dance of Refusal',
            cost: 4,
            type: 'Event',
            supertypes: ['Instant'],
            instant: true,
            text: [
                'Counter target card or ability.',
                'Draw a card.',
            ],
            boost: 3,
            illus: 'dance-refusal',
        },
        behavior: {
            targets: [
                {
                    types: ['Card', 'Ability'],
                },
            ],
            resolve: (G, ctx, targets, card) => {
                ACTIONS.counter(G, ctx, targets[0]);
                ACTIONS.draw(G, ctx, card.controller);
            },
        },
    },

    {
        key: 'XXX-E15',
        data: {
            name: 'Cycle of Life',
            cost: 3,
            type: 'Event',
            text: [
                'Target Character you don\'t control loses 1 action.',
                'Target Character you control gains 1 action.',
            ],
            boost: 2,
            illus: 'cycle-of-life',
        },
        behavior: {
            targets: [
                {
                    types: ['Character'],
                    condition: (G, ctx, params) => {
                        // The target must be controller by the opponent.
                        const card = getCard(G, ctx, params.item.cardID);
                        const target = getCard(G, ctx, params.targetID);
                        return card.controller !== target.controller;
                    },
                },
                {
                    types: ['Character'],
                    condition: (G, ctx, params) => {
                        // The target must be controller by the card's controller.
                        const card = getCard(G, ctx, params.item.cardID);
                        const target = getCard(G, ctx, params.targetID);
                        return card.controller === target.controller;
                    },
                },
            ],
            resolve: (G, ctx, targets, card) => {
                const firstTarget = getCard(G, ctx, targets[0]);
                if (firstTarget && firstTarget.zone === CONSTANTS.ZONES.BOARD) {
                    ACTIONS.loseActions(G, ctx, firstTarget, 1);
                }

                const secondTarget = getCard(G, ctx, targets[1]);
                if (secondTarget && secondTarget.zone === CONSTANTS.ZONES.BOARD) {
                    ACTIONS.gainActions(G, ctx, secondTarget, 1);
                }
            },
        },
    },

    // ------------------------ \\
    // ------ SOULSHIFTS ------ \\
    // ------------------------ \\

    {
        key: 'XXX-S01',
        data: {
            name: 'Penal Servitude',
            cost: 2,
            type: 'Soulshift',
            text: [
                'Soulshift Character',
                'Soulshifted Character gains -3 Prestige.',
            ],
            boost: 2,
            illus: 'penal-servitude',
        },
        behavior: {
            modifiers: [
                {
                    type: 'target',
                    effect: card => ({
                        ...card,
                        prestige: card.prestige - 3,
                    }),
                },
            ],
            targets: [
                {
                    types: ['Character'],
                },
            ],
            resolve: (G, ctx, targets, card) => {
                const target = getCard(G, ctx, targets[0]);
                const modifierID = { sourceID: card.id, modifierIndex: 0 };
                target.modifiers.push(modifierID);
            },
        },
    },

    {
        key: 'XXX-S02',
        data: {
            name: 'Years of Practice',
            cost: 2,
            type: 'Soulshift',
            supertypes: ['Instant'],
            instant: true,
            text: [
                'Soulshift Character',
                'Soulshifted Character gains +2 Prestige.',
            ],
            boost: 2,
            illus: 'years-practice',
        },
        behavior: {
            modifiers: [
                {
                    type: 'target',
                    effect: card => ({
                        ...card,
                        prestige: card.prestige + 2,
                    }),
                },
            ],
            targets: [
                {
                    types: ['Character'],
                },
            ],
            resolve: (G, ctx, targets, card) => {
                const target = getCard(G, ctx, targets[0]);
                const modifierID = { sourceID: card.id, modifierIndex: 0 };
                target.modifiers.push(modifierID);
            },
        },
    },

    {
        key: 'XXX-S03',
        data: {
            name: 'Restriction Field',
            cost: 3,
            type: 'Soulshift',
            text: [
                'Soulshift Character',
                'Soulshifted Character cannot use any actions.',
            ],
            boost: 3,
            illus: 'restriction-field',
        },
        behavior: {
            modifiers: [
                {
                    type: 'target',
                    effect: card => ({
                        ...card,
                        exhausted: true,
                    }),
                },
            ],
            targets: [
                {
                    types: ['Character'],
                },
            ],
            resolve: (G, ctx, targets, card) => {
                const target = getCard(G, ctx, targets[0]);
                const modifierID = { sourceID: card.id, modifierIndex: 0 };
                target.modifiers.push(modifierID);
            },
        },
    },

    // ------------------------ \\
    // -------- DECREES ------- \\
    // ------------------------ \\

    {
        key: 'XXX-D00',
        data: {
            name: 'Hegemony',
            type: 'Decree',
            text: [
                ':adopted → You win the game.',
            ],
            influence: 4,
            illus: 'hegemony',
        },
        behavior: {
        },
    },

    {
        key: 'XXX-D01',
        data: {
            name: 'Infiltrated Informant',
            type: 'Decree',
            text: [
                'Whenever an opponent proposes a Decree, target Character you control'
                + ' gains +2 Prestige until end of turn.',
            ],
            influence: 1,
            illus: 'infiltrated-informant',
        },
        behavior: {
            modifiers: [
                {
                    type: 'target',
                    effect: card => ({
                        ...card,
                        prestige: card.prestige + 2,
                    }),
                },
            ],
            triggers: [
                {
                    type: 'DECREE_IS_PROPOSED',
                    zone: CONSTANTS.ZONES.BOARD,
                    targets: [
                        {
                            types: ['Character'],
                            condition: (G, ctx, params) => {
                                // The target must be controller by the Decree's controller.
                                const card = getCard(G, ctx, params.item.cardID);
                                const target = getCard(G, ctx, params.targetID);
                                return card.controller === target.controller;
                            },
                        },
                    ],
                    condition: (G, ctx, params) => {
                        // Only when an opponent proposes.
                        const card = getCard(G, ctx, params.cardID);
                        const decree = getCard(G, ctx, params.triggeredByID);
                        return card.controller !== decree.owner;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const target = getCard(G, ctx, targets[0]);

                        const modifierID = { sourceID: params.cardID, modifierIndex: 0 };
                        // Make this last only until end of turn.
                        modifierID.removeOn = 'END_OF_TURN_CLEANUP';
                        target.modifiers.push(modifierID);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-D02',
        data: {
            name: 'Shadow Agent',
            type: 'Decree',
            text: [
                ':6: Gain 1 influence.',
            ],
            influence: 1,
            illus: 'shadow-agent',
        },
        behavior: {
            abilities: [
                {
                    cost: {
                        energy: 6,
                    },
                    resolve: (G, ctx, targets, card) => {
                        ACTIONS.gainInfluence(G, ctx, card.controller, 1);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-D03',
        data: {
            name: 'Autopsy',
            type: 'Decree',
            text: [
                'Whenever a Character you control dies, draw a card.',
            ],
            influence: 2,
            illus: 'autopsy',
        },
        behavior: {
            triggers: [
                {
                    type: 'CHARACTER_DIES',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        // Verify the character is controlled by the player.
                        const decree = getCard(G, ctx, params.cardID);
                        const character = getCard(G, ctx, params.triggeredByID);
                        return decree.controller === character.controller;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        ACTIONS.draw(G, ctx, card.controller, 1);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-D04',
        data: {
            name: 'Notable Breakthrough',
            type: 'Decree',
            text: [
                ':adopted → Draw two cards.'
            ],
            influence: 2,
            illus: 'notable-breakthrough',
        },
        behavior: {
            triggers: [
                {
                    type: 'DECREE_IS_ADOPTED',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const card = getCard(G, ctx, params.cardID);
                        ACTIONS.draw(G, ctx, card.controller, 2);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-D05',
        data: {
            name: 'Rule of Terror',
            type: 'Decree',
            text: [
                'At the beginning of your turn, target Character an opponent controls' +
                ' gains -2 Prestige until end of turn.'
            ],
            influence: 2,
            illus: 'rule-of-terror',
        },
        behavior: {
            modifiers: [
                {
                    type: 'target',
                    effect: card => ({ ...card, prestige: card.prestige - 2 }),
                },
            ],
            triggers: [
                {
                    type: 'BEGINNING_OF_TURN',
                    zone: CONSTANTS.ZONES.BOARD,
                    targets: [
                        {
                            types: ['Character'],
                            condition: (G, ctx, params) => {
                                // The target must be controlled by an opponent.
                                const thisCard = getCard(G, ctx, params.item.cardID);
                                const target = getCard(G, ctx, params.targetID);
                                return thisCard.controller !== target.controller;
                            },
                        },
                    ],
                    condition: (G, ctx, params) => {
                        // Only at the beginning of your turn.
                        const thisCard = getCard(G, ctx, params.cardID);
                        return thisCard.controller === ctx.currentPlayer;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const target = getCard(G, ctx, targets[0]);

                        const modifierID = { sourceID: params.cardID, modifierIndex: 0 };
                        // Make this last only until end of turn.
                        modifierID.removeOn = 'END_OF_TURN_CLEANUP';
                        target.modifiers.push(modifierID);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-D06',
        data: {
            name: 'Wind of Change',
            type: 'Decree',
            text: [
                'Whenever you cast an Event, gain 1 influence.',
            ],
            influence: 2,
            illus: 'wind-change',
        },
        behavior: {
            triggers: [
                {
                    type: 'EVENT_IS_CAST',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        const thisCard = getCard(G, ctx, params.cardID);
                        const event = getCard(G, ctx, params.triggeredByID);
                        return thisCard.controller === event.owner;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const thisCard = getCard(G, ctx, params.cardID);
                        ACTIONS.gainInfluence(G, ctx, thisCard.controller, 1);
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-D07',
        data: {
            name: 'Time Toll',
            type: 'Decree',
            text: [
                ':adopted → Characters your opponents control lose 1 action.',
            ],
            influence: 3,
            illus: 'time-toll',
        },
        behavior: {
            triggers: [
                {
                    type: 'DECREE_IS_ADOPTED',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const thisCard = getCard(G, ctx, params.cardID);
                        G.cards.filter(c => (
                            c.controller !== thisCard.controller
                            && c.zone === CONSTANTS.ZONES.BOARD
                            && c.type === 'Character'
                        )).forEach(c => ACTIONS.loseActions(G, ctx, c, 1));
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-D08',
        data: {
            name: 'Voice of Nature',
            type: 'Decree',
            text: [
                'Character cards you cast cost :1 less to cast.',
            ],
            influence: 3,
            illus: 'voice-nature',
        },
        behavior: {
            modifiers: [
                {
                    type: 'global',
                    zone: CONSTANTS.ZONES.BOARD,
                    effect: (card, source) => {
                        if (
                            card.type === 'Character'
                            && card.zone === CONSTANTS.ZONES.HAND
                            && card.owner === source.controller
                        ) {
                            return { ...card, cost: card.cost - 1 };
                        }

                        return card;
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-D09',
        data: {
            name: 'Strength in Numbers',
            type: 'Decree',
            text: [
                'Characters you control gain +1 Prestige.'
            ],
            influence: 4,
            illus: 'strength-numbers',
        },
        behavior: {
            modifiers: [
                {
                    type: 'global',
                    zone: CONSTANTS.ZONES.BOARD,
                    effect: (card, source) => {
                        if (
                            card.type === 'Character'
                            && card.zone === CONSTANTS.ZONES.BOARD
                            && card.owner === source.controller
                        ) {
                            return { ...card, prestige: card.prestige + 1 };
                        }

                        return card;
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-D10',
        data: {
            name: 'Open Borders',
            type: 'Decree',
            text: [
                ':adopted → Create two 2 / 2 Citizen Characters.',
            ],
            influence: 4,
            illus: 'open-borders',
        },
        behavior: {
            triggers: [
                {
                    type: 'DECREE_IS_ADOPTED',
                    zone: CONSTANTS.ZONES.BOARD,
                    condition: (G, ctx, params) => {
                        return params.cardID === params.triggeredByID;
                    },
                    resolve: (G, ctx, targets, params) => {
                        const thisCard = getCard(G, ctx, params.cardID);
                        ACTIONS.createToken(G, ctx, thisCard.controller, CARDS, 'XXX-C05');
                        ACTIONS.createToken(G, ctx, thisCard.controller, CARDS, 'XXX-C05');
                    },
                },
            ],
        },
    },

    {
        key: 'XXX-D11',
        data: {
            name: 'Dramatic Arts',
            type: 'Decree',
            text: [
                ':3: :instant — Target Character gains +1 Prestige until end of turn.',
            ],
            influence: 1,
            illus: 'dramatic-arts',
        },
        behavior: {
            modifiers: [
                {
                    type: 'target',
                    effect: card => ({ ...card, prestige: card.prestige + 1 }),
                },
            ],
            abilities: [
                {
                    instant: true,
                    cost: {
                        energy: 3,
                    },
                    targets: [
                        {
                            types: ['Character'],
                        },
                    ],
                    resolve: (G, ctx, targets, card) => {
                        const target = getCard(G, ctx, targets[0]);
                        if (target && target.zone === CONSTANTS.ZONES.BOARD) {
                            const modifierID = { sourceID: card.id, modifierIndex: 0 };
                            // Make this last only until end of turn.
                            modifierID.removeOn = 'END_OF_TURN_CLEANUP';
                            target.modifiers.push(modifierID);
                        }
                    },
                },
            ],
        },
    },
];


export default CARDS;
