import React from 'react';

import Level from './Level';


const levelsParser = {
    rule: /(:[bgrpyc]:)/,
    tag: x => {
        const color = x.slice(1, -1);
        return <Level color={ color } />;
    },
};

export default levelsParser;
