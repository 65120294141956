import CONSTANTS from '../constants';
import { getCard, moveToZone } from '../utils';


export default function mulligan(G, ctx, cardIDs) {
    const cards = G.cards.filter(
        c => (
            cardIDs.includes(c.id)
            && c.owner === ctx.playerID
            && c.zone === CONSTANTS.ZONES.HAND
        )
    ).sort(
        (a, b) => a.lastMovedOn > b.lastMovedOn
    );
    const deck = G.secret[ctx.playerID].deck;

    // Put cards back on the bottom of the deck.
    cards.forEach(card => {
        moveToZone(G, ctx, card, CONSTANTS.ZONES.DECK);
        deck.push(card.id);

        // ctx.effects.discardCard({ cardID: card.id, owner: card.owner });
    });

    // Draw new cards to replace them.
    cards.forEach(() => {
        const newCardID = deck.shift();
        const newCard = getCard(G, ctx, newCardID);
        moveToZone(G, ctx, newCard, CONSTANTS.ZONES.HAND);

        // ctx.effects.drawCard({ cardID: newCardID, owner: newCard.owner });
    });

    G.secret[ctx.playerID].deck = ctx.random.Shuffle(deck);
    G.properties[ctx.playerID].readyToPlay = true;

    // Removed until https://github.com/delucis/bgio-effects/issues/41 is resolved.
    // if (cards.length) {
    //     ctx.effects.wait();
    // }
}
