import React from 'react';
import { Link } from 'react-router-dom';

import './RulesGuide.css';

import Layout from './screens/Layout';


export default function RulesGuide() {
    return (
        <Layout><section className='rules-guide content'>
            <h1>How to Play Souls</h1>
            <p>
                <strong>Souls</strong> is an online card game where two players compete to influence the world around them by adopting Decrees. Players summon Characters and use those Characters&#39; prestige to influence the game in their favor. The goal of the game is to be the first player to adopt their 4th decree. When that happens, the game ends immediately.
            </p>
            <p>
                Here&#39;s an example card from each different card type you will meet in the game:
            </p>
            <p>
                <img
                    src="/img/tuto/card-types.png"
                    referrerPolicy="no-referrer"
                    alt="Decree, Character, Soulshift, Event"
                />
            </p>
            <p>
                Decrees are permanents (they stay on the board once played) that can gain you an immediate advantage, or an incremental one, or that slightly change the rules. Characters are also permanents, that you will play and use to adopt your Decrees. Soulshifts are the last kind of permanents, and they are attached to another permanent when played. You will use them to improve your Characters or block those of your opponent. Finally, Events are resolved immediately as you play them, then go to your discard pile, called the &quot;void&quot;.
            </p>
            <p>
                You might notice that Decree cards are different from the others. That is because Decrees go into a different deck than the rest of your cards. You start a game with 2 decks: one for your Decrees, containing 5 unique cards, and one for your regular cards, containing 30 of them.
            </p>
            <p>
                <em>Note: in this prototype, all players have the exact same decks containing the same 30 unique cards. However, each player will have a different Decree deck, randomly chosen between the 2 possible decks. <Link to='/cards'>You can read all the cards here</Link>.</em>
            </p>

            <h2>Anatomy of a Card</h2>
            <p>
                All cards have a name (at the top), an illustration, a type (Decree, Character, Soulshift or Event), some text, and stats (at the bottom).
            </p>
            <p>
                Except for Decrees, all cards also have a <strong>cost</strong> (top left corner), which represents the number of energy you need to pay to play that card, and a <strong>boost</strong> value (middle of the bottom line), which represents the added influence this card can bring when discarding it during a conflict (<em>see The Conflict Phase</em>).
            </p>
            <p>
                <img
                    src="/img/tuto/card-anatomy.png"
                    referrerPolicy="no-referrer"
                    alt="A card with its different parts named"
                />
            </p>

            <img src="/img/tuto/decree.png" alt="A Decree card" className="side" />
            <h3>Decrees</h3>
            <p>
                A Decree has text that applies once it is adopted, and an <strong>objective</strong> value at the bottom. That is the influence you need to reach in order for that Decree to be adopted (<em>see The Conflict Phase</em>).
            </p>

            <div className="clear" />

            <h3>Characters</h3>
            <img src="/img/tuto/character.png" alt="A Character card" className="side" />
            <p>
                A Character has text that applies when it is on the board, and it has two special stats: the prestige (bottom left) and the actions (bottom right). The <strong>prestige</strong> value represents the ability of that Character to influence the world, notably during conflicts (<em>see The Conflict Phase</em>). The <strong>actions</strong> value is the number of actions this Character will be able to make during its life time. Each action a Character takes consumes one of its action points, and once that number reaches zero, the Character will die at the end of the turn (<em>see Actions</em>).
            </p>
            <p>
                When you play a Character, you can start using it right away, except to join your assembly during a conflict (see <em>The Conflict Phase</em>). You can activate its abilities in that same turn you played it, or you can use it in a conflict on your opponent&#39;s next turn. However, you will have to wait until your next turn to be able to use that Character to adopt a Decree.
            </p>

            <div className="clear" />

            <img src="/img/tuto/soulshift.png" alt="A Soulshift card" className="side" />
            <h3>Soulshifts</h3>
            <p>
                A Soulshift has a line that tells you to what type of permanent it can be attached (in this prototype, it only goes on Characters), then other lines that tell you of the effects it has on that permanent. Soulshifts stay on the board as long as the permanent they are attached to is on the board. If that permanent leaves the board for any reason, the Soulshift is detached from it and sent to its owner&#39;s void.
            </p>

            <div className="clear" />

            <h3>Events</h3>
            <img src="/img/tuto/event.png" alt="An Event card" className="side" />
            <p>
                An Event has text that describes what it does when resolved. Once an Event is resolved, it goes straight to its owner&#39;s void.
            </p>

            <div className="clear" />

            <h2>The Board</h2>
            <p>
                <img
                    src="/img/tuto/board.png"
                    referrerPolicy="no-referrer"
                    alt="The game screen with descriptions of each part"
                />
            </p>
            <p>
                The game screen is split into two parts: the bottom half is yours, and the top half is your opponent&#39;s. Each half has approximately the same elements:
            </p>
            <ul>
                <li>The <strong>board</strong> is where permanents (Characters, Decrees, Soulshifts) go when they are played.</li>
                <li>The <strong>void</strong> is where cards go when they are discarded. This is also where permanents go when they die or are destroyed.</li>
                <li>The <strong>deck</strong> shows how many cards you have left in your deck.</li>
                <li>The <strong>Decree deck</strong> shows, revealed, your top Decree.</li>
                <li>To the left of your avatar, you will find your <strong>energy</strong> (available / maximum). It is used to play cards or activate abilities (see <em>Playing Cards</em>).</li>
                <li>To the right of your avatar, you will find your <strong>influence</strong>. That value will vary throughout the game, and is used to support or oppose Decrees (see <em>The Conflict Phase</em>).</li>
                <li>Your <strong>hand</strong> has the cards you can play (see <strong>Playing Cards</strong>).</li>
                <li>The <strong>current step</strong> shows where the game is currently at (see <strong>Turn Structure</strong>).</li>
                <li>Finally, the <strong>main actions</strong> has buttons for the main actions you can play at a given time. This is where you&#39;ll pass the turn, gain energy or draw cards, declare assemblies, etc. </li>
            </ul>
            <p>
                You can right-click on any permanent on the board to see a more detailed version of that object. You can also use that to view Soulshifts attached to a Character.
            </p>


            <h2>The Conflict Phase</h2>
            <p>
                In order to win the game, you have to adopt 4 Decrees. To adopt a Decree, you first need to &quot;propose&quot; it, which leads to a conflict phase, during which you and your opponent will convoke assemblies. These assemblies will decide if the Decree is adopted or rejected.
            </p>
            <h3>Proposing a Decree</h3>
            <ul>
                <li>You can propose up to one Decree per turn.</li>
                <li>You can propose a Decree only during your main phase.</li>
                <li>You can propose a Decree only when the stack is empty (see <em>Instants and the Stack</em>).</li>
                <li>The Decree you can propose is the top Decree of your Decree deck.</li>
                <li>The top Decree of each player&#39;s deck is always visible by all players.</li>
                <li>A Decree that you can propose is highlighted in blue.</li>
                <li>To propose a Decree, click on it.</li>
            </ul>
            <p>
                <img
                    src="/img/tuto/decree-ready.png"
                    referrerPolicy="no-referrer"
                    alt="A Decree that can be proposed."
                />
            </p>
            <p>
                <img
                    src="/img/tuto/propose-decree.png"
                    referrerPolicy="no-referrer"
                    alt="The interface to propose a Decree."
                />
            </p>
            <p>
                Clicking a Decree leads to an interface where you can see the details of that Decree. Clicking the &quot;Propose&quot; button will start the conflict phase, which will decide if the Decree is adopted or rejected.
            </p>

            <h3>Creating Assemblies</h3>
            <p>
                Once a conflict is started around a Decree, you and your opponent will both constitute an assembly that will serve to defend your interest. When you propose, your goal is to make sure the Decree is adopted, and when your opponent proposes, your goal is, of course, to reject the Decree.
            </p>
            <p>
                <img
                    src="/img/tuto/conflict-empty.png"
                    referrerPolicy="no-referrer"
                    alt="Characters influence the Decree&#39;s adoption"
                />
            </p>
            <p>
                An assembly is composed of any number of two types of cards. The main one is Characters from your board. Adding a Character to your assembly contributes its <b>prestige</b> value to the assembly&#39;s authority. But you can also add at most one card directly from your hand, face down, for free — well, except that you discard that card at the end of the conflict. A boost card, whatever its type (Character, Event, Soulshift… ), contributes its <b>boost</b> value to the assembly&#39;s authority. Your opponent will not know what card it is until the end of the assembly creation step, neither will you know the boost card your opponent might use.
            </p>
            <p>
                Adding elements to the assemblies is done turn by turn, starting with the player proposing the Decree. You can select any number of Characters, and up to one card from your hand to use as a boost, then set your assembly and pass to your opponent. They then do the same thing, and pass back to you. This constitution of your assemblies goes on until one player makes no changes to their assembly. That then marks the end of the creation of assemblies: boost cards are revealed, and then the game moves on to the conflict resolution with the assemblies as they are.
            </p>
            <p>
                <img
                    src="/img/tuto/conflict-with-boost.png"
                    referrerPolicy="no-referrer"
                    alt="Boosting changes the outcome of the conflict"
                />
            </p>
            <p>
                When you propose a Decree, you cannot add to your assembly any Characters that you have played this turn. That restriction doesn&#39;t apply to the other player: they could play an Instant Character before the assembly step begins, and use that Character in their assembly (see <em>Instants and the Stack</em>).
            </p>
            <p>
                Adding a Character to your assembly will exhaust that Character, and it costs it an action. That means that the Character won&#39;t be able to use any other actions until the beginning of your next turn. See <em>Playing Abilities</em> for more details.
            </p>
            <p>
                If you propose a Decree, but cannot, or decide not to, add any cards to your assembly, you must abandon that Decree. Doing so will make you lose 1 influence.
            </p>
            <p>
                Note that the order of cards in the assembly matters, as some effects care about a Character or boost card being Prime, i.e. the first element of an assembly.
            </p>

            <h3>Resolving the conflict</h3>
            <p>
                To resolve a conflict, the game will compute the <b>resulting authority</b> of the proposing player. To do that, it takes that player&#39;s influence plus its assembly&#39;s authority. It then subtracts the opponent&#39;s influence and their assembly&#39;s authority. If the result is greated than or equal to the Decree&#39;s objective, then the Decree is adopted. Otherwise, it is rejected.
            </p>
            <p>
                <img
                    src="/img/tuto/conflict-revealed.png"
                    referrerPolicy="no-referrer"
                    alt="The board at the end of a conflict"
                />
            </p>
            <p>
                The equation can be expressed as follows:
            </p>
            <p>
                <code>
                    Proposing player&#39;s influence
                    <br />+ Proposing player&#39;s assembly authority
                    <br />- Defending player&#39;s influence
                    <br />- Defending player&#39;s assembly authority
                    <br />&gt;= Decree&#39;s objective
                </code>
            </p>
            <p>
                When a player adopts a Decree, their opponent gains 1 influence.
            </p>
            <h3>Final Decree</h3>
            <p>
                The fourth and last Decree you propose will always be the same, special Decree. It is called Hegemony, and has an objective of 4. Once you have adopted your third Decree, you will see that special Decree at the top of your Decree deck.
            </p>


            <h2>Playing Cards</h2>
            <p>
                Playing a card is quite easy: you have to have enough energy to pay its cost. Your current energy is indicated to the left of your avatar. It shows the energy you have available, a slash, and the size of your energy reserve. At the beginning of your turn, your energy fills back to its maximum.
            </p>
            <p>
                <img
                    src="/img/tuto/energy.png"
                    referrerPolicy="no-referrer"
                    alt="A player&#39;s energy numbers"
                />
            </p>
            <p>
                A card that you can play is highlighted with a blue halo in your hand. To play a card, simply click it.
            </p>

            <h3>Gaining Energy</h3>
            <p>
                During each of your turns, you have access to a special action to choose amongst 2 options: you can either rise your energy reserve by one, giving you one energy in the process, or you can draw an extra card. (<em>Note that you draw a card at the beginning of your turn anyway.</em> )
            </p>
            <p>
                <img
                    src="/img/tuto/special-action.png"
                    referrerPolicy="no-referrer"
                    alt="Two buttons showing the special actions"
                />
            </p>
            <p>
                You can only play that special action during your main step, and only if there is nothing else happening (meaning the stack is empty, see <em>Instants and the Stack</em>).
            </p>

            <h3>Playing Abilities</h3>
            <p>
                Some permanents have activated abilities, meaning that they are abilities you can activate by paying a cost. They all follow this form: <code>cost: effect</code>. You need to be able to pay the cost to activate the ability. A permanent that has an ability that can be activated will have a blue highlight on the board.
            </p>
            <p>
                If an ability requires actions (see <em>Actions</em>), then using it will exhaust the permanent. That means that the permanent will not be usable for anything else until the beginning of your next turn, when it will be "readied" again. Note that joining an assembly during a conflict require an action, and thus will exhaust your Character. Consequently, for Characters with abilities requiring actions, you need to choose between using your Character in a conflict, or using the ability.
            </p>
            <p>
                <img
                    src="/img/tuto/activated-ability.png"
                    referrerPolicy="no-referrer"
                    alt="A card with an ability that can be activated"
                />
            </p>

            <h3>Instants and the Stack</h3>
            <p>
                By default, playing a card or activating an ability can only be done during your main step on your turn, and only when nothing else is happening. However, some cards and abilities are marked as &quot;Instant&quot;. Those can be played or activated at almost any point of the game, during your turn or your opponent&#39;s.
            </p>
            <p>
                <img
                    src="/img/tuto/instants.png"
                    referrerPolicy="no-referrer"
                    alt="An instant card and a card with an instant ability"
                />
            </p>
            <p>
                Every effect that comes from a card will go on a stack of effects. That stack is a &quot;first in, last out&quot;. It means that players can add effects to the stack, and the last one to have been added will be resolved first. Only instant cards and abilities can be played when the stack is not empty. That mechanism allows you to answer actions from your opponent. Players can add effects to the stack as much as they can. When both players stop adding new effects, either because they &quot;pass&quot; or because they cannot do anything more, the game starts to resolve effects on the stack, from newest to oldest.
            </p>
            <p>
                Note that the game will let you play cards or abilities whenever possible, and also offer you to pass if you don&#39;t want to do anything at the time. This is called a priority exchange. You can use the Space key as a shortcut to pass priority. If you cannot perform any actions at a given time, the game will automatically pass for you.
            </p>
            <p>
                A priority exchange always starts with the active player (the one whose turn it currently is). Playing a card, activating an ability, adding anything to the stack will start a new priority exchange. Priority exchanges happen whenever there is something in the stack, but also at the end of each step. Notably, there is a priority exchange after a player proposes a Decree (before the assembly step starts), as well as after assemblies are constituted (before the conflict resolution).
            </p>


            <h2>Actions</h2>
            <p>
                Characters have a limited number of actions, which you can think of as their lifespan. There are two kinds of actions: joining an assembly during a conflict and activating an ability that requires action points. Joining an assembly is a special game action that always cost 1 action point. On the other hand, the number of action points required to activate an ability is indicated in that ability&#39;s cost. Each action symbol counts for 1 action point to spend.</p>
            <p>
                <img
                    src="/img/tuto/actions.png"
                    referrerPolicy="no-referrer"
                    alt="A card with an ability that requires actions"
                />
            </p>
            <p>
                At the end of each turn, all players sacrifice all Characters they control with zero actions.
            </p>


            <h2>Turn Structure</h2>
            <p>
                When a game starts, both player receive the same deck of 30 unique cards. Each player receives one of the two possible Decree decks. All decks are shuffled, and players draw 3 cards. Then both players have a mulligan phase, during which they can choose any number of cards to send back to the bottom of their deck. For each card sent back, they will draw a new one. Then their deck is shuffled again.
            </p>
            <p>
                When both players are done with their mulligan, the game starts. The first player is chosen at random (and is known during mulligan). Starting from the first player, players take successive turns that always follow the same structure. The exception is the first turn of the first player: they do not draw a card. That is to counterbalance the advantage of playing first.
            </p>
            <p>
                The regular structure of a turn is as follows:
            </p>
            <ol>
                <li>Ready all permanents, draw a card.</li>
                <li>Main step (the active player can play cards, activate abilities, propose a Decree or play a special action).</li>
                <li>Sacrifice step (all permanents with 0 actions are sacrificed).</li>
                <li>Clean up (effects that last &quot;until end of turn&quot; are removed).</li>
            </ol>
            <p>
                If the active player proposes a Decree during their main step, a conflict phase starts:
            </p>
            <ol>
                <li>Create assemblies.</li>
                <li>Resolve conflict.</li>
            </ol>
            <p>
                The game goes on until a player adopts their fourth Decree.
            </p>


            <h2>Keywords</h2>
            <p>
                Some cards have in their text keywords. Those can be followed by an arrow (→), in which case they are triggers. The keyword describes the condition for the effect to trigger, and what follows the arrow is the effect.
            </p>
            <p>
                Others will be followed by a long dash (—). That indicates a static effect, and the keyword describes the condition for that effect to apply.
            </p>

            <h3>Adopted</h3>
            <p>
                Only appears on Decrees. This effect triggers when the Decree enters the board.
            </p>

            <h3>Summon</h3>
            <p>
                Only appears on Characters. This effect triggers when the Character enters the board.
            </p>

            <h3>Death</h3>
            <p>
                Only appears on Characters. This effect triggers when the Characters enters the void from the board.
            </p>

            <h3>Boost</h3>
            <p>
                This effect triggers when the card is used to boost during a conflict. Note that this triggers after the assembly step is finished (when boost cards are revealed), but before the conflict resolves.
            </p>

            <h3>Splendor</h3>
            <p>
                Only appears on Characters. This effect grants the Character&#39;s controller a given amount of influence (the number following the <strong>Splendor</strong> keyword) when it enters the board, and removes that same amount when the Character leaves the board.
            </p>

            <h3>Prime</h3>
            <p>
                Only appears on Characters. It reads as "as long as this Character is the first element of your assembly, apply X" where X is what follows the dash.
            </p>

            <h3>Boost Prime</h3>
            <p>
                This effect is the same as the <strong>Prime</strong> effect, but when boosting with the card. It applies only if the card is used as a boost, and is the first element in an assembly.
            </p>


            <h2>Changelog</h2>

            <h3>Current version</h3>
            <p>Features:</p>
            <ul>
            </ul>
            <p>Cards:</p>
            <ul>
                <li><b>Meeting with Death</b> - Makes you lose 1 influence when played.</li>
                <li><b>Exploited Peasant</b> - Costs 2, has 2 prestige.</li>
                <li><b>Infiltrated Informant</b> - Gives +2 prestige (up from +1).</li>
                <li><b>Rule of Terror</b> - Gives -2 prestige (up from -1).</li>
            </ul>

            <h3>2020, November 6</h3>
            <p>Features:</p>
            <ul>
                <li>Reworked the Conflict phase, see <em>The Conflict Phase</em>.</li>
                <li>After you propose a Decree, if you choose to abandon it, you lose 1 influence.</li>
                <li>Added the Splendor keyword ability.</li>
                <li>Added the Prime and Boost Prime keyword effects.</li>
                <li>The draw step has been removed. Players go directly to their main phase.</li>
                <li>Cards on the board have less information and bigger pictures.</li>
            </ul>
            <p>Cards:</p>
            <ul>
                <li><b>Focused Philosopher</b> - Uses Splendor instead of simply giving influence.</li>
                <li><b>Governor</b> - Uses Splendor instead of simply giving influence.</li>
                <li><b>Shadow Agent</b> - Ability costs 6 energy instead of 4.</li>
                <li><b>Murder</b> - Boost mecanism changed completely.</li>
                <li><b>Mayor</b> - Less base prestige, gains prestige when Prime.</li>
                <li><b>Jean Bernard</b> - Gains prestige when you play events while he is Prime.</li>
                <li><b>Tax Collector</b> - Weaker when Prime, doesn't remove influence anymore.</li>
                <li><b>Divination</b> - Draw a card when boosting as Prime.</li>
                <li><b>Activist</b> - Less prestige, increase prestige of your other Characters when Prime.</li>
                <li><b>Open Borders</b> - Create two Citizens instead of one.</li>
            </ul>

            <h3>2020, October 13</h3>
            <p>Features:</p>
            <ul>
                <li>Removed impact. Decrees can now be proposed cost-free, with a maximum of one per turn.</li>
                <li>Added influence. Influence is a number that starts at 0 for both players, and that can be negative. Influence is used in computing the result of a conflict.</li>
                <li>Conflict formula is now: <code>Influence(S) + Prestige(S) + Boost(S) - Influence(O) - Prestige(O) - Boost(O) >= Objective(Decree)</code> <em>(S = Supporter, O = Objector)</em></li>
                <li>When a Decree is adopted, the objecting player gains 1 influence.</li>
                <li>You need to adopt 4 Decrees to win (instead of 3).</li>
                <li>Each player&#39;s 4th Decree is always a special Decree with objective 4.</li>
            </ul>
            <p>Cards:</p>
            <ul>
                <li><b>Inspiring Flute Player</b> - Ability costs 2 energy and gives 2 actions.</li>
                <li><b>Desperate Geisha</b> (was: Geisha of Paradise) - Doesn&#39;t make energy anymore.</li>
                <li><b>Tax Collector</b> - Removes influence when it dies.</li>
                <li><b>Focused Philosopher</b> - Adds influence instead of impact.</li>
                <li><b>Governor</b> - Adds influence instead of impact.</li>
                <li><b>Shadow Agent</b> - Adds influence instead of swapping with top Decree.</li>
                <li><b>Wind of Change</b> - Adds influence instead of impact.</li>
            </ul>

            <h3>2020, September 23</h3>
            <p>Initial version of the game.</p>
        </section></Layout>
    );
}
