import React from 'react';

import './Void.css';

import CONSTANTS from '../constants';
import Card from '../card/Card';


export default function Void(props) {
    const { G, owner, handleClick } = props;

    const void_ = G.cards.filter(c => (
        c.zone === CONSTANTS.ZONES.VOID
        && c.owner === owner
    )).sort(
        (first, second) => first.lastMovedOn > second.lastMovedOn
    );

    let card = null;
    if (void_[void_.length - 1]) {
        card = <Card card={ void_[void_.length - 1] } />;
    }

    return (
        <div className='void' onClick={ () => handleClick(owner) }>
            { card }
            <p><span>{ void_.length }</span></p>
        </div>
    );
}
