import targets from '../modules/targets';

import setTargets from './setTargets';


/**
 * Target a game element.
 */
export default function target(G, ctx, targetID, targetType) {
    const allTargetsDone = targets.setTarget(G, ctx, targetID, targetType);
    if (allTargetsDone) {
        setTargets(G, ctx);
    }
}
