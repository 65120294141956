import React from 'react';

import './CardWithZoom.css';

import Card from '../card/Card';
import CardMiniature from '../card/CardMiniature';


export default function CardWithZoom({
    card,
    zoomPosition = 'left',
    size = 'normal',
    animation = '',
}) {
    return (
        <div className={ `card-with-zoom ${size} ${animation}`}>
            <div className={ `zoom ${zoomPosition}` }>
                <Card card={ card } />
            </div>
            <CardMiniature card={ card } />
        </div>
    );
}
