import React from 'react';

import './Card.css';

import COLORS from '../colors';
import Ability from './Ability';
import CardStats from './CardStats';
import CardText from './CardText';
import CardType from './CardType';
import Energy from './Energy';
import Level from './Level';


export default function Card({ card }) {
    if (!card) {
        return 'Card data is missing';
    }

    if (card.type === 'Ability') {
        return <Ability card={ card } />;
    }

    const levels = (card.levels || '').split('');
    const cardColor = COLORS[levels[0]] || 'colorless';

    return <div className={ `card color-${cardColor}` }>
        <div className={ `type-${card.type.toLowerCase().replace(' ', '-')}` }>
            { card.type === 'Decree' ? null :
            <section className="requirements">
                <div className="cost">
                    <Energy value={ card.cost } />
                </div>
                <ul className="levels">
                    { levels.map((l, index) => <li key={ index }><Level color={ l } /></li>) }
                </ul>
            </section> }

            <section className="title">
                <h1>{ card.name }</h1>
            </section>

            <section className="image">
                <img src={ `/img/illus/${card.illus}.jpg` } alt="" />
            </section>

            <CardType card={ card } />
            <CardText card={ card } />
            <CardStats card={ card } />
        </div>
    </div>;
}
