import * as ACTIONS from '../actions';

import priority from '../modules/priority';


/**
 * Special action: gain an energy.
 *
 * Only one special action can played on each player's turn.
 */
export default function gainEnergy(G, ctx) {
    if (G.properties[ctx.currentPlayer].hasPlayedSpecialAction) {
        return;
    }

    ACTIONS.gainEnergy(G, ctx, ctx.currentPlayer, 1);
    G.properties[ctx.currentPlayer].hasPlayedSpecialAction = true;

    priority.resume(G, ctx);
}
