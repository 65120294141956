import cast from '../modules/cast';
import priority from '../modules/priority';
import stack from '../modules/stack';
import targets from '../modules/targets';


/**
 * Set the targets for an item and move on.
 */
export default function setTargets(G, ctx) {
    const item = G.targets.currentItem;
    targets.cancel(G, ctx);

    if (item && item.type === 'Ability' && item.genre === 'triggered') {
        stack.setItemTargets(G, ctx, item);
        priority.start(G, ctx, true);
    }
    else if (item) {
        cast.castElement(G, ctx, item);
    }
}
