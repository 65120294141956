import ALL_CARDS from './cards';
import create from './create';


const CARDS = ALL_CARDS.filter(c => c.data.type !== 'Decree');


// Used in development to limit decks to only this subset of cards. Uses card keys to filter.
const onlyCards = [
];


export function createCard(ctx, playerID, key) {
    let cardKey = key;

    if (onlyCards.length) {
        const cards = CARDS.filter(c => onlyCards.includes(c.key));
        const index = ctx.random.Die(cards.length) - 1;
        cardKey = cards[index].key;
    }

    return create(ctx, playerID, ALL_CARDS, cardKey);
}


export function createDecree(ctx, playerID, cardKey) {
    return create(ctx, playerID, ALL_CARDS, cardKey);
}
