import React from 'react';

import './BoostCard.css';

import CardBack from './CardBack';
import CardWithZoom from '../ui/CardWithZoom';


export default function BoostCard(props) {
    const { card, index, board, owner, playerID } = props;

    let cls = 'boost-card';
    let content = <CardBack />;

    if (card) {
        const zoomPosition = [
            (index < board.length - 3) ? 'right' : 'left',
            (owner !== playerID) ? 'top' : 'bottom',
        ].join(' ');

        content = <CardWithZoom
            card={ card }
            size='small'
            zoomPosition={ zoomPosition }
            animation='slit-in'
        />;

        cls += ' highlighted';
    }

    return (
        <div className={ cls }>
            { content }
        </div>
    );
}
