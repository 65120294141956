import CONSTANTS from '../constants';


export default function updateCardCounts(G, ctx) {
    ctx.playOrder.forEach(playerID => {
        const hand = G.cards.filter(c => (
            c.zone === CONSTANTS.ZONES.HAND
            && c.owner === playerID
        ));
        G.properties[playerID].cardCounts.handSize = hand.length;
        G.properties[playerID].cardCounts.deckSize = G.secret[playerID].deck.length;
        G.properties[playerID].cardCounts.decreesSize = G.secret[playerID].decreeDeck.length;
    });
}
