import React from 'react';

import './Level.css';

import COLORS from '../colors';


export default function Level({ color }) {
    return <img className="level" src={ `/img/${COLORS[color]}-gem.svg` } alt="" />;
}
