import React from 'react';

import './ErrorScreen.css';


export default function ErrorScreen(props) {
    const { errorMsg } = props;

    return (
        <section className='error-screen'>
            <div>
                <h2>Ooooh crap! There was an error…</h2>
                <h3>I am very sorry. =(</h3>
                <p>
                    Please tell your friendly developer about this problem.
                    Here is the error message:
                </p>
                <p className="error">{ errorMsg }</p>
                <p>
                    <a href="/">
                        Go back and try again
                    </a>
                </p>
            </div>
        </section>
    );
}
