import React from 'react';

import './Modal.css';


export default function Modal({ cls, children, ...props }) {
    return (
        <section className={ `modal ${cls}` } { ...props }>
            { children }
        </section>
    );
}
