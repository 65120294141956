import React from 'react';

import './SoulsBoard.css';

import steps from '../modules/steps';

import GameOverScreen from '../screens/GameOverScreen';

import CardInspector from './CardInspector';
import ConflictSummary from './ConflictSummary';
import Controls from './Controls';
import DecreeDeck from './DecreeDeck';
import Hand from './Hand';
import Library from './Library';
import Mulligan from './Mulligan';
import PlayerBoard from './PlayerBoard';
import PlayerMetadata from './PlayerMetadata';
import PlayerTurnAnimation from './PlayerTurnAnimation';
import ProposeDecree from './ProposeDecree';
import Stack from './Stack';
import Void from './Void';
import VoidExplorer from './VoidExplorer';


export default function SoulsBoard(props) {
    const { G, ctx, playerID, matchData, matchID } = props;
    const opponentID = ctx.playOrder.find(x => x !== playerID);

    const [ showVoidOfPlayer, setShowVoidOfPlayer ] = React.useState(null);
    const [ showProposeDecree, setShowProposeDecree ] = React.useState(null);
    const [ showCardInspector, setShowCardInspector ] = React.useState(null);
    const [ showTargetsForItem, setShowTargetsForItem ] = React.useState(null);
    const [ forceTargetsForItem, setForceTargetsForItem ] = React.useState(false);

    React.useEffect(
        () => {
            const items = G.stack.items;
            if (!forceTargetsForItem && items.length) {
                const itemID = items[items.length - 1].id;
                if (showTargetsForItem !== itemID) {
                    setShowTargetsForItem(itemID);
                }
            }
            if (!forceTargetsForItem && !items.length) {
                setShowTargetsForItem(null);
            }
        },
        [showTargetsForItem, setShowTargetsForItem, forceTargetsForItem, G]
    );

    function closeVoidExplorer(e) {
        e.preventDefault();
        setShowVoidOfPlayer(null);
    }

    let voidExplorer = null;
    if (showVoidOfPlayer !== null) {
        voidExplorer = <VoidExplorer
            owner={ showVoidOfPlayer }
            handleClose={ closeVoidExplorer }
            { ...props }
        />;
    }

    function closeCardInspector(e) {
        e.preventDefault();
        setShowCardInspector(null);
    }

    function playerName(id) {
        return matchData.find(p => p.id === parseInt(id)).name;
    }

    let cardInspector = null;
    if (showCardInspector !== null) {
        cardInspector = <CardInspector
            cardID={ showCardInspector }
            handleClose={ closeCardInspector }
            { ...props }
        />;
    }

    let cls = 'souls-board';
    if (playerID === ctx.currentPlayer) {
        cls += ' active';
    }
    if (steps.isInConflictPhase(G, ctx)) {
        cls += ' conflict';
    }

    return <main onContextMenu={ e => e.preventDefault() }>
        { !ctx.gameover ? null :
        <GameOverScreen
            gameover={ ctx.gameover }
            playerID={ playerID }
        />
        }
        <PlayerTurnAnimation { ...props } />
        <Mulligan { ...props } />
        <ProposeDecree
            showProposeDecree={ showProposeDecree }
            setShowProposeDecree={ setShowProposeDecree }
            { ...props }
        />
        { voidExplorer }
        { cardInspector }
        <Stack
            setShowCardInspector={ setShowCardInspector }
            setShowTargetsForItem={ setShowTargetsForItem }
            setForceTargetsForItem={ setForceTargetsForItem }
            { ...props }
        />
        <div className={ cls }>
            <div>
                <p className='player-name opponent'>{ playerName(opponentID) }</p>
                <Hand owner={ opponentID } { ...props } />
            </div>

            <section className='extra'>
                <div></div>
                <PlayerMetadata owner={ opponentID } { ...props } />
                <div className='player-zones'>
                    <DecreeDeck owner={ opponentID } { ...props } />
                    <Library owner={ opponentID } { ...props } />
                    <Void
                        owner={ opponentID }
                        handleClick={ setShowVoidOfPlayer }
                        { ...props }
                    />
                </div>
            </section>

            <PlayerBoard
                owner={ opponentID }
                setShowCardInspector={ setShowCardInspector }
                showTargetsForItem={ showTargetsForItem }
                { ...props }
            />

            <ConflictSummary
                setShowCardInspector={ setShowCardInspector }
                showTargetsForItem={ showTargetsForItem }
                { ...props }
            />

            <PlayerBoard
                owner={ playerID }
                setShowCardInspector={ setShowCardInspector }
                showTargetsForItem={ showTargetsForItem }
                { ...props }
            />

            <section className='extra'>
                <div className='player-zones'>
                    <Void owner={ playerID } handleClick={ setShowVoidOfPlayer } { ...props } />
                    <Library owner={ playerID } { ...props } />
                    <DecreeDeck
                        owner={ playerID }
                        setShowProposeDecree={ setShowProposeDecree }
                        { ...props }
                    />
                </div>
                <PlayerMetadata owner={ playerID } { ...props } />
                <Controls { ...props } />
            </section>

            <div>
                <p className='debug-data'>{ `Match ID: ${matchID}` }</p>
                <p className='player-name'>{ playerName(playerID) }</p>
                <Hand owner={ playerID } { ...props } />
            </div>
        </div>
    </main>;
}
