import React from 'react';
import createMarker from 'react-content-marker';

import energyParser from './energyParser';
import levelsParser from './levelsParser';


const parsers = [
    {
        rule: ':action',
        tag: x => <img src='/img/action.svg' alt='action' />,
    },
    energyParser,
    levelsParser,
    {
        rule: /(:\w+)/,
        // Note that this removes the leading colon.
        tag: x => <b>{ x[1].toUpperCase() + x.slice(2) }</b>,
    },
    {
        rule: /(\(.+\))/,
        tag: x => <i>{ x }</i>,
    },
];


export default class CardTextLine extends React.Component {
    render() {
        const { card, className, line } = this.props;

        const MarkedContent = createMarker(
            parsers.concat([{
                rule: ':~',
                tag: x => <span>{ card.name }</span>,
            }])
        );

        return <p className={ className }>
            <MarkedContent>
                { line }
            </MarkedContent>
        </p>;
    }
}
