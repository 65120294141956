import updateCardCounts from './updateCardCounts';
import updateCards from './updateCards';
import updateDecks from './updateDecks';


export default function updateState(G, ctx) {
    updateCardCounts(G, ctx);
    updateCards(G, ctx);
    updateDecks(G, ctx);
}
