import CONSTANTS from '../constants';
import { getCard, getBehavior, hasValidTargets } from '../utils';

import cast from '../modules/cast';
import stack from '../modules/stack';
import targets from '../modules/targets';


/**
 * Play an ability of a card.
 */
export default function activateAbility(G, ctx, cardID, abilityID) {
    const card = getCard(G, ctx, cardID);
    const behavior = getBehavior(G, ctx, card);
    const ability = behavior.abilities[abilityID];

    if (
        // Verify card is on player's board.
        card.zone !== CONSTANTS.ZONES.BOARD

        // Verify the card belongs to the player.
        || card.controller !== ctx.playerID

        // Verify the ability has valid targets.
        || !hasValidTargets(G, ctx, card, ability)
    ) {
        return;
    }

    // If the ability requires actions.
    if (
        ability.cost.actions
        && (
            // Verify permanent is not already exhausted.
            card.exhausted

            // Verify ability costs can be paid.
            || card.actions < ability.cost.actions
        )
    ) {
        return;
    }

    // Verify energy costs can be paid.
    if (
        ability.cost.energy
        && G.properties[ctx.playerID].energy.available < ability.cost.energy
    ) {
        return;
    }

    const item = stack.createAbilityItem(G, ctx, card, abilityID);
    if (ability.targets && ability.targets.length) {
        targets.startTargeting(G, ctx, item);
    }
    else {
        cast.castActivatedAbility(G, ctx, item);
    }
}
