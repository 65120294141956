import playCard from './playCard';


/**
 * Play a card from a player's hand.
 */
export default function playInstantCard(G, ctx, cardID) {
    const card = G.cards.find(c => c.id === cardID);

    // Verify the card is instant.
    if (!card.instant) {
        return;
    }

    return playCard(G, ctx, cardID);
}
