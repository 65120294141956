import React from 'react';
import { Lobby } from 'boardgame.io/react';
import { EffectsBoardWrapper } from 'bgio-effects/react';

import './Game.css';

import GameRenderer from './GameRenderer';
import Souls from './Souls';
import AnimationStateHandler from './AnimationStateHandler';


function renderer(props) {
    return <GameRenderer { ...props } />;
}


// Configure server location based on the current environment.
const protocol = window.location.protocol;
const location = window.location.hostname;
const port = (process.env.NODE_ENV === 'production') ? window.location.port : '8000';
let server = protocol + '//' + location;
if (port) {
    server += ':' + port;
}


const board = EffectsBoardWrapper(
    AnimationStateHandler,
    {
        updateStateAfterEffects: true,
    }
);


export default function Game() {
    return <Lobby
        gameServer={ server }
        lobbyServer={ server }
        gameComponents={ [{ game: Souls, board }] }
        renderer={ renderer }
        // debug={ true }
    />;
}
