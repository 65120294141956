import CONSTANTS from '../constants';
import { getCard } from '../utils';

import steps from '../modules/steps';


export default function updateDecks(G, ctx) {
    ctx.playOrder.forEach(playerID => {
        // Reveal top decree card if we're not in the conflict phase.
        if (!steps.isInConflictPhase(G, ctx)) {
            // Show top decree of the deck.
            const topDecree = getCard(G, ctx, G.secret[playerID].decreeDeck[0]);
            topDecree.revealed = true;
        }

        // Refresh deck lists based on card zones.
        G.secret[playerID].deck = G.secret[playerID].deck.filter(cardID => {
            return getCard(G, ctx, cardID).zone === CONSTANTS.ZONES.DECK;
        });
        G.secret[playerID].decreeDeck = G.secret[playerID].decreeDeck.filter(cardID => {
            return getCard(G, ctx, cardID).zone === CONSTANTS.ZONES.DECK;
        });

        // Refresh public deck lists based on secret ones.
        G.public[playerID].deck = G.secret[playerID].deck.map(cardID => {
            const card = getCard(G, ctx, cardID);
            return card.revealed ? card.id : null;
        });
        G.public[playerID].decreeDeck = G.secret[playerID].decreeDeck.map(cardID => {
            const card = getCard(G, ctx, cardID);
            return card.revealed ? card.id : null;
        });
    });
}
