import CONSTANTS from '../constants';
import MESSENGER from '../messenger';
import {
    getBehavior,
    getCard,
    getStripedState,
    hasValidTargets,
    moveToZone,
} from '../utils';
import updateState from '../state/updateState';


export function setup(G, ctx) {
    G.stack = {
        // The items currently on the stack, waiting to be resolved.
        items: [],
        // Items that are on the stack but require their targets to be set before moving on.
        requiringTargets: [],
        // Incremental ID for stack objects.
        currentStackId: 10000,
    };
    return G;
}


function getTrigger(G, ctx, card, triggerIndex) {
    const behavior = getBehavior(G, ctx, card);
    return behavior.triggers[triggerIndex];
}


function runTriggers(G, ctx, type, params) {
    const triggers = G.triggeredAbilities.filter(t => t.type === type);
    triggers.forEach(triggerID => {
        const card = getCard(G, ctx, triggerID.cardID);
        const trigger = getTrigger(G, ctx, card, triggerID.triggerIndex);
        const newParams = {
            ...params,
            cardID: card.id,
        };

        if (trigger.condition && !trigger.condition(G, ctx, newParams)) {
            return;
        }

        // Does this trigger require targets to be set?
        const requireTargets = trigger.targets && trigger.targets.length;

        if (hasValidTargets(G, ctx, card, trigger)) {
            const item = createTriggerItem(G, ctx, card, triggerID.triggerIndex, newParams);
            add(G, ctx, item, requireTargets);
        }
    });
}


export function registerTriggers(G, ctx, card) {
    const behavior = getBehavior(G, ctx, card);
    if (behavior && behavior.triggers) {
        behavior.triggers.forEach((trigger, index) => {
            if (trigger.zone === card.zone) {
                // Register each trigger.
                const triggerID = {
                    cardID: card.id,
                    triggerIndex: index,
                    type: trigger.type,
                };
                G.triggeredAbilities.push(triggerID);
            }
        });
    }
}


function resolvePermanent(G, ctx, card) {
    // Move card to player's board.
    moveToZone(G, ctx, card, CONSTANTS.ZONES.BOARD);
}


function resolveCharacter(G, ctx, item) {
    const card = getCard(G, ctx, item.cardID);
    resolvePermanent(G, ctx, card);
}


function resolveSoulshift(G, ctx, item) {
    const card = getCard(G, ctx, item.cardID);
    const target = getCard(G, ctx, item.targets[0]);

    if (target.zone !== CONSTANTS.ZONES.BOARD) {
        // Target is not on the board anymore, this is discarded.
        moveToZone(G, ctx, card, CONSTANTS.ZONES.VOID);
    }
    else {
        resolvePermanent(G, ctx, card);

        // Attach soulshift to its target.
        card.attachedTo = target.id;

        // Resolve the card.
        const behavior = getBehavior(G, ctx, card);
        behavior.resolve(G, ctx, item.targets, card, STACK);
    }
}


function resolveDecree(G, ctx, item) {
    const card = getCard(G, ctx, item.cardID);
    resolvePermanent(G, ctx, card);
}


function resolveEvent(G, ctx, item) {
    const card = getCard(G, ctx, item.cardID);
    const behavior = getBehavior(G, ctx, card);

    // We have all targets, resolve card.
    behavior.resolve(G, ctx, item.targets, card, STACK);

    // Move card to player's void.
    moveToZone(G, ctx, card, CONSTANTS.ZONES.VOID);
}


export function resolveCard(G, ctx, item) {
    const card = getCard(G, ctx, item.cardID);

    if (card.type === 'Character') {
        resolveCharacter(G, ctx, item);
    }
    else if (card.type === 'Soulshift') {
        resolveSoulshift(G, ctx, item);
    }
    else if (card.type === 'Decree') {
        resolveDecree(G, ctx, item);
    }
    else if (card.type === 'Event') {
        resolveEvent(G, ctx, item);
    }
    else {
        throw new TypeError('Unsupported card type: ' + card.type);
    }
}


export function resolveActivatedAbility(G, ctx, item) {
    const card = getCard(G, ctx, item.cardID);
    const behavior = getBehavior(G, ctx, card);
    const ability = behavior.abilities[item.ability];

    ability.resolve(G, ctx, item.targets, card, STACK);
}


export function resolveTriggeredAbility(G, ctx, item) {
    const card = getCard(G, ctx, item.cardID);
    const behavior = getBehavior(G, ctx, card);
    const trigger = behavior.triggers[item.trigger];

    trigger.resolve(G, ctx, item.targets, item.params, STACK);
}


function resolveStackTop(G, ctx) {
    const items = G.stack.items;

    ctx.effects.resolve({
        itemID: items[items.length - 1].id,
        G: getStripedState(G, ctx),
    });

    const stackTop = items.pop();

    if (stackTop.type === 'Card') {
        resolveCard(G, ctx, stackTop);
    }
    else if (stackTop.type === 'Ability') {
        if (stackTop.genre === 'activated') {
            resolveActivatedAbility(G, ctx, stackTop);
        }
        else if (stackTop.genre === 'triggered') {
            resolveTriggeredAbility(G, ctx, stackTop);
        }
        else {
            throw new Error(`Unsupported item genre: ${stackTop.genre}`);
        }
    }
    else {
        throw new Error(`Unsupported item type: ${stackTop.type}`);
    }

    updateState(G, ctx);
}


function setItemTargets(G, ctx, item) {
    // Find item in our list of items requiring targets.
    const itemIndex = G.stack.requiringTargets.findIndex(i => i.id === item.id);

    if (itemIndex < 0) {
        throw new Error('trying to set targets for a trigger that does not require them');
    }

    // Remove it, and put in the stack proper, with targets.
    G.stack.requiringTargets.splice(itemIndex, 1);
    add(G, ctx, item);
}


function createCardItem(G, ctx, card) {
    return {
        type: 'Card',
        id: G.stack.currentStackId++,
        targets: [],
        cardID: card.id,
    };
}


function createAbilityItem(G, ctx, card, ability) {
    return {
        type: 'Ability',
        genre: 'activated',
        id: G.stack.currentStackId++,
        targets: [],
        cardID: card.id,
        ability,
    };
}


function createTriggerItem(G, ctx, card, trigger, params) {
    return {
        type: 'Ability',
        genre: 'triggered',
        id: G.stack.currentStackId++,
        targets: [],
        cardID: card.id,
        trigger,
        params,
    };
}


function add(G, ctx, item, requireTargets) {
    if (requireTargets) {
        G.stack.requiringTargets.push(item);
    }
    else {
        G.stack.items.push(item);
        ctx.effects.addToStack({
            itemID: item.id,
            G: getStripedState(G, ctx),
        });
    }
}


export function remove(G, ctx, id) {
    const index = G.stack.items.findIndex(item => item.id === id);
    if (index >= 0) {
        const items = G.stack.items.splice(index, 1);
        return items[0];
    }
    return null;
}


export function isEmpty(G, ctx) {
    return (
        G.stack.items.length === 0
        && G.stack.requiringTargets.length === 0
    );
}


MESSENGER.subscribe(MESSENGER.REGISTER_TRIGGERS, registerTriggers);
MESSENGER.subscribe(MESSENGER.TRIGGER, runTriggers);


const STACK = {
    add,
    createCardItem,
    createAbilityItem,
    createTriggerItem,
    isEmpty,
    remove,
    resolveCard,
    resolveStackTop,
    setItemTargets,
    setup,
};


export default STACK;
