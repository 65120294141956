import React from 'react';

import Button from '../ui/Button';


export default function WaitingScreen(props) {
    const { cancelMatch } = props;
    return (
        <section>
            <div>
                <p>Looking for an opponent…</p>
                <Button importance='secondary' onClick={ cancelMatch }>Cancel</Button>
            </div>
        </section>
    );
}
