import React from 'react';

import './Controls.css';

import steps from '../modules/steps';
import Button from '../ui/Button';
import {
    getItemBehavior,
} from '../utils';


function EndTurnButton(props) {
    return <Button type="submit">
        End Turn
    </Button>;
}


function PassButton(props) {
    return <Button type="submit">
        Pass
    </Button>;
}


function MyTurnButton(props) {
    return <Button type="submit">
        My Turn
    </Button>;
}


function SetAssemblyButton(props) {
    return <Button type="submit">
        Set Assembly
    </Button>;
}


function AbandonDecreeButton(props) {
    return <Button type="submit">
        Abandon Decree
    </Button>;
}


function FinishAssemblyButton(props) {
    return <Button type="submit">
        Finish Assembly
    </Button>;
}


function SetTargetsButton(props) {
    const { G } = props;
    const count = G.targets.currentItem.targets.length;

    return <Button type="submit">
        { count } Target{count === 1 ? '' : 's'}
    </Button>;
}


function OpponentTurnButton(props) {
    return <Button disabled>
        Waiting for Opponent
    </Button>;
}


function WaitButton(props) {
    return <Button disabled>
        <img src="/img/hourglass.svg" alt="Please Wait" />
    </Button>;
}


function getCurrentActionHelp(props) {
    const { G, ctx, isActive, playerID } = props;
    if (!isActive) {
        return '';
    }

    if (ctx.activePlayers && ctx.activePlayers[playerID]) {
        const stage = ctx.activePlayers[playerID];

        // If waiting to target something.
        if (stage === 'target') {
            const item = G.targets.currentItem;
            const behavior = getItemBehavior(G, ctx, item);

            const count = behavior.targets.length - item.targets.length;

            if (count === 1) {
                return 'Choose a target';
            }
            else {
                return `Choose ${count} targets`;
            }
        }
    }

    return '';
}


function SimpleMainAction(props) {
    const { MainActionBtn, mainActionMove } = props;

    const mainMove = (e) => {
        e.preventDefault();

        if (mainActionMove) {
            props.moves[mainActionMove]();
        }
    };

    const actionShortcut = e => {
        if (e.key === ' ') {
            mainMove(e);
        }
    }

    React.useEffect(() => {
        window.addEventListener('keydown', actionShortcut, true);
        return () => {
            window.removeEventListener('keydown', actionShortcut, true);
        };
    });

    return (
        <form onSubmit={ mainMove }>
            <MainActionBtn { ...props } />
        </form>
    );
}


function SpecialAction(props) {
    const { moves } = props;
    return (
        <div className='special-action'>
            <Button
                onClick={ () => moves.gainEnergy() }
                title="Gain an energy"
            >
                Energy
            </Button>
            <Button
                onClick={ () => moves.draw() }
                title="Draw a card"
            >
                Draw
            </Button>
        </div>
    );
}


function MainAction(props) {
    const { G, ctx, isActive, playerID, moves, animating } = props;

    if (animating) {
        return <SimpleMainAction
            MainActionBtn={ WaitButton }
            mainActionMove={ null }
            { ...props }
        />;
    }

    // If player is not active, there's nothing to do but wait.
    if (!isActive || ctx.phase === 'mulligan') {
        return <SimpleMainAction
            MainActionBtn={ OpponentTurnButton }
            mainActionMove={ null }
            { ...props }
        />;
    }

    // Player is in a stage.
    if (ctx.activePlayers && ctx.activePlayers[playerID]) {
        const stage = ctx.activePlayers[playerID];

        // If waiting to target something.
        if (stage === 'target') {
            const content = [];

            const item = G.targets.currentItem;
            const behavior = getItemBehavior(G, ctx, item);
            const nextTarget = behavior.targets[item.targets.length];

            if (nextTarget && nextTarget.optional) {
                content.push(
                    <SimpleMainAction
                        MainActionBtn={ SetTargetsButton }
                        mainActionMove={ 'setTargets' }
                        key='btn-set-targets'
                        { ...props }
                    />
                );
            }

            if (G.targets.mayCancel) {
                content.push(
                    <Button
                        onClick={ () => moves.cancel() }
                        importance='secondary'
                        key='btn-cancel'
                    >
                        Cancel
                    </Button>
                );
            }

            return (
                <div>
                    { content }
                </div>
            );
        }

        // If responding to something on the stack.
        else if (stage === 'respond') {
            const step = steps.STEPS_DATA[G.steps.current];
            if (!G.stack.items.length && step.next === null && playerID !== ctx.currentPlayer) {
                return <SimpleMainAction
                    MainActionBtn={ MyTurnButton }
                    mainActionMove={ 'pass' }
                    { ...props }
                />;
            }
            else {
                return <SimpleMainAction
                    MainActionBtn={ PassButton }
                    mainActionMove={ 'pass' }
                    { ...props }
                />;
            }
        }

        // If in conflict stage.
        else if (stage === 'assembly') {
            const participants = G.conflict.participants[playerID];

            if (participants.current.length) {
                return <SimpleMainAction
                    MainActionBtn={ SetAssemblyButton }
                    mainActionMove={ 'validate' }
                    { ...props }
                />;
            }

            if (playerID === ctx.currentPlayer && participants.declared.length === 0) {
                return <SimpleMainAction
                    MainActionBtn={ AbandonDecreeButton }
                    mainActionMove={ 'validate' }
                    { ...props }
                />;
            }

            return <SimpleMainAction
                MainActionBtn={ FinishAssemblyButton }
                mainActionMove={ 'validate' }
                { ...props }
            />;
        }
    }

    // Player is in their main phase and hasn't played their special action yet.
    else if (!G.properties[playerID].hasPlayedSpecialAction) {
        return <SpecialAction { ...props } />;
    }

    return <SimpleMainAction
        MainActionBtn={ EndTurnButton }
        mainActionMove={ 'finishTurn' }
        { ...props }
    />;
}


export default function Controls(props) {
    const { G } = props;

    const currentActionHelp = getCurrentActionHelp(props);

    let step = 'Unknown';
    if (G.steps.current) {
        step = steps.STEPS_DATA[G.steps.current].name;
    }

    return <div className="controls">
        <h3>{ `${step} Step` }</h3>
        <p>{ currentActionHelp }</p>
        <MainAction { ...props } />
    </div>;
}
