import { TurnOrder } from 'boardgame.io/core';
import { EffectsPlugin } from 'bgio-effects/plugin';

import * as ACTIONS from './actions';
import CONSTANTS from './constants';
import MESSENGER from './messenger';
import EFFECTS_CONFIG from './effects-config';
import * as MOVES from './moves';
import { removeEndedModifiers } from './utils';

import {
    setup,
    stripSecrets,
    updateState,
} from './state';

import priority from './modules/priority';
import steps from './modules/steps';
import targets from './modules/targets';


const Souls = {
    name: 'Souls',

    plugins: [ EffectsPlugin(EFFECTS_CONFIG) ],

    setup,
    playerView: stripSecrets,
    disableUndo: true,

    moves: {
        playCard: {
            move: MOVES.playCard,
            client: false,
        },
        activateAbility: {
            move: MOVES.activateAbility,
            client: false,
        },
        finishTurn: {
            move: MOVES.finishTurn,
            client: false,
        },
        draw: {
            move: MOVES.draw,
            client: false,
        },
        gainEnergy: {
            move: MOVES.gainEnergy,
            client: false,
        },
        proposeDecree: {
            move: MOVES.proposeDecree,
            client: false,
        },
    },

    phases: {
        mulligan: {
            start: true,

            moves: {
                mulligan: {
                    move: MOVES.mulligan,
                    client: false,
                    moveLimit: 1,
                },
            },

            turn: {
                order: TurnOrder.CUSTOM_FROM('turnOrder'),

                onMove: (G, ctx) => {
                    updateState(G, ctx);
                },

                stages: {
                    chooseCards: {},
                },
            },

            onBegin: (G, ctx) => ctx.events.setActivePlayers({ all: 'chooseCards' }),
            endIf: (G, ctx) => ctx.playOrder.every(p => G.properties[p].readyToPlay),
        },
    },

    turn: {
        // This is a mix between the RESET and CUSTOM_FROM turn orders from boardgame.io.
        // It's needed here in order to keep the custom order created during setup, but also
        // not starting with the second player (as ending the mulligan phase passes a turn).
        order: {
            playOrder: (G, ctx) => G.turnOrder,
            first: () => 0,
            next: (G, ctx) => (ctx.playOrderPos + 1) % ctx.numPlayers,
        },

        onBegin: (G, ctx) => {
            // Allow player to play a special action.
            G.properties[ctx.currentPlayer].hasPlayedSpecialAction = false;

            // Player hasn't ended their turn yet.
            G.properties[ctx.currentPlayer].endedTurn = false;

            // Allow player to propose a decree.
            G.conflict.proposedThisTurn = false;
            G.conflict.adoptedThisTurn = false;

            // Ready all permanents on player's board.
            const permanents = G.cards.filter(c => (
                c.controller === ctx.currentPlayer
                && c.zone === CONSTANTS.ZONES.BOARD
            ));
            permanents.forEach(card => {
                card.exhausted = false;
                card.freshOnBoard = false;
            });

            // Refill energy.
            const energy = G.properties[ctx.currentPlayer].energy;
            energy.available = energy.maximum;

            // Trigger beginning of turn events.
            MESSENGER.publish(MESSENGER.TRIGGER, G, ctx, 'BEGINNING_OF_TURN');

            // Draw a card, except of the first turn of the first player.
            // Note that the first turn is the mulligan phase, so when the first player gets to
            // their turn, it's actually turn 2.
            if (ctx.turn > 2) {
                ACTIONS.draw(G, ctx, ctx.currentPlayer);
            }

            steps.start(G, ctx);

            updateState(G, ctx);
        },

        onEnd: (G, ctx) => {
            removeEndedModifiers(G, ctx, 'END_OF_TURN_CLEANUP');
            updateState(G, ctx);
        },

        onMove: (G, ctx) => {
            updateState(G, ctx);
        },

        stages: {
            respond: {
                moves: {
                    pass: {
                        move: (G, ctx) => {
                            priority.next(G, ctx);
                        },
                        client: false,
                    },
                    playCard: {
                        move: MOVES.playInstantCard,
                        client: false,
                    },
                    activateAbility: {
                        move: MOVES.activateInstantAbility,
                        client: false,
                    },
                },
            },
            target: {
                moves: {
                    targetPermanent: {
                        move: (G, ctx, target) => {
                            MOVES.target(G, ctx, target.id, target.type);
                        },
                        client: false,
                    },
                    targetPlayer: {
                        move: (G, ctx, target) => {
                            MOVES.target(G, ctx, target, 'Player');
                        },
                        client: false,
                    },
                    targetCard: {
                        move: (G, ctx, target) => {
                            MOVES.target(G, ctx, target, 'Card');
                        },
                        client: false,
                    },
                    targetAbility: {
                        move: (G, ctx, target) => {
                            MOVES.target(G, ctx, target, 'Ability');
                        },
                        client: false,
                    },
                    setTargets: {
                        move: MOVES.setTargets,
                        client: false,
                    },
                    cancel: {
                        move: (G, ctx) => {
                            if (!G.targets.mayCancel) {
                                return;
                            }

                            targets.cancel(G, ctx);
                            ctx.events.endStage();
                            priority.resume(G, ctx);
                        },
                        client: false,
                    },
                },
            },
            assembly: {
                moves: {
                    toggleCharacter: {
                        move: MOVES.toggleCharacter,
                        client: false,
                    },
                    toggleBoost: {
                        move: MOVES.toggleBoost,
                        client: false,
                    },
                    validate: {
                        move: MOVES.validate,
                        client: false,
                    },
                },
            },
        },
    },

    endIf: (G, ctx) => {
        for (let playerID of ctx.playOrder) {
            const decrees = G.cards.filter(
                c => (
                    c.type === 'Decree'
                    && c.zone === CONSTANTS.ZONES.BOARD
                    && c.controller === playerID
                )
            );
            if (decrees.length >= CONSTANTS.ADOPTED_DECREES_COUNT_TO_WIN) {
                return { winner: playerID };
            }
        };
    },
};

export default Souls;
