import CONSTANTS from '../constants';

import { createDecree } from '../data/card';
import { createDeck, createDecreeDeck } from '../data/decks';
import priority from '../modules/priority';
import stack from '../modules/stack';
import steps from '../modules/steps';
import targets from '../modules/targets';

import updateCards from './updateCards';


export default function setup(ctx) {
    let cards = [];
    const properties = {};
    const secret = {};
    const publicData = {};
    const conflictParticipants = {};

    ctx.playOrder.forEach((playerID, index) => {
        // Choose a deck randomly.
        const deckType = (['aggro', 'control', 'midrange'])[ctx.random.Die(3) - 1];

        // Create initial deck of cards.
        let deck = createDeck(ctx, playerID, deckType);
        deck = ctx.random.Shuffle(deck);
        cards = cards.concat(deck);

        // Create initial deck of decrees.
        let decreeDeck = createDecreeDeck(ctx, playerID, deckType);
        decreeDeck = ctx.random.Shuffle(decreeDeck);
        decreeDeck[0].revealed = true;
        cards = cards.concat(decreeDeck);

        // Create the special last decree.
        let lastDecree = createDecree(ctx, playerID, 'XXX-D00');
        lastDecree.zone = null;
        lastDecree.revealed = true;
        cards.push(lastDecree);

        const hand = [
            deck.shift(),
            deck.shift(),
            deck.shift(),
        ];
        hand.forEach(c => cards.find(s => s.id === c.id).zone = CONSTANTS.ZONES.HAND);

        secret[playerID] = {
            deck: deck.map(c => c.id),
            decreeDeck: decreeDeck.map(c => c.id),
        };

        publicData[playerID] = {
            deck: deck.map(c => c.revealed ? c.id : null),
            decreeDeck: decreeDeck.map(c => c.revealed ? c.id : null),
        };

        conflictParticipants[playerID] = {
            declared: [],
            current: [],
        };

        properties[playerID] = {
            readyToPlay: false,
            endedTurn: false,
            influence: CONSTANTS.STARTING_INFLUENCE,
            energy: {
                available: CONSTANTS.STARTING_ENERGY,
                maximum: CONSTANTS.STARTING_ENERGY,
            },
            cardCounts: {
                deckSize: deck.length,
                handSize: hand.length,
                decreesSize: decreeDeck.length,
            },
            hasPlayedSpecialAction: false,
        };
    });

    let G = {
        turnOrder: ctx.random.Shuffle(ctx.playOrder),
        cards,
        properties,
        secret,
        public: publicData,
        conflict: {
            proposedThisTurn: false,
            adoptedThisTurn: false,
            participants: conflictParticipants,
            assemblyFinished: false,
        },
        globalModifiers: [],
        triggeredAbilities: [],
    };

    // Apply modules setup functions.
    G = priority.setup(G, ctx);
    G = stack.setup(G, ctx);
    G = steps.setup(G, ctx);
    G = targets.setup(G, ctx);

    // Update all cards to have their default data.
    updateCards(G, ctx);

    return G;
}
