import React from 'react';

import './PlayerEnergy.css';


export default function PlayerEnergy(props) {
    const { G, owner } = props;
    return <div className='player-energy'>
        <p className='desc'>Energy</p>
        <p className='value'>
            { G.properties[owner].energy.available } / { G.properties[owner].energy.maximum }
        </p>
    </div>;
}
