import React from 'react';

import './PlayerAvatar.css';

import { getItemBehavior } from '../utils';


export default function PlayerAvatar(props) {
    const { G, ctx, isActive, moves, owner, playerID } = props;
    const stage = ctx.activePlayers && ctx.activePlayers[playerID];

    function targetPlayer() {
        if (!isActive) {
            return;
        }

        if (stage === 'target') {
            moves.targetPlayer(owner);
        }
    }

    // If targeting, find the next target criteria to highlight this if appropriate.
    let nextTarget = null;
    if (stage === 'target') {
        const item = G.targets.currentItem;
        const behavior = getItemBehavior(G, ctx, item);
        nextTarget = behavior.targets[item.targets.length];
    }

    let cls = 'player-avatar';
    if (
        isActive
        && stage === 'target'
        && nextTarget.types.includes('Player')
    ) {
        cls += ' targetable';
    }

    if (owner === ctx.currentPlayer) {
        cls += ' active';
    }

    if (owner !== playerID) {
        cls += ' opponent';
    }

    return <div className={ cls } onClick={ targetPlayer }>
        <img src={ `/img/avatars/avatar-player-${owner}.jpg`} alt='' />
    </div>;
}
