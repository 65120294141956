import { PlayerView } from 'boardgame.io/core';

import CONSTANTS from '../constants';
import { getCard } from '../utils';


export default function stripSecrets(G, ctx, playerID) {
    function getRedactedConflictCard(id) {
        const card = getCard(G, ctx, id);
        if (
            card
            && card.zone === CONSTANTS.ZONES.CONFLICT
            && card.owner !== playerID
            && !card.revealed
        ) {
            return 'BOOST';
        }
        return id;
    }

    const redacted = PlayerView.STRIP_SECRETS(G, ctx, playerID);

    const cards = redacted.cards.filter(c => {
        // Show the card data if…
        return (
            // The card belongs to the player
            c.owner === playerID

            // The card is revealed
            || c.revealed

            // The card is in a public zone
            || CONSTANTS.PUBLIC_ZONES.includes(c.zone)
        );
    });

    // Remove the card IDs of boost cards that are not revealed.
    const participants = {};
    for (let player in redacted.conflict.participants) {
        const original = redacted.conflict.participants[player];
        participants[player] = {
            declared: original.declared.map(getRedactedConflictCard),
            current: original.current.map(getRedactedConflictCard),
        };
    }

    const conflict = {
        ...(redacted.conflict),
        participants,
    };

    const state = {
        ...redacted,
        cards,
        conflict,
    };

    return state;
}
