import {
    getCard,
    getOriginal,
    getBehavior,
} from '../utils';


function getModifier(G, ctx, { sourceID, modifierIndex }) {
    const source = getCard(G, ctx, sourceID);
    const behavior = getBehavior(G, ctx, source);

    if (!behavior || !behavior.modifiers) {
        return null;
    }

    const modifier = behavior.modifiers[modifierIndex].effect;

    return [ modifier, source ];
}


function getUpdatedCard(G, ctx, sourceCard) {
    let modifiers = [ ...G.globalModifiers ];
    modifiers = modifiers.concat(sourceCard.modifiers);

    const originalCard = getOriginal(G, ctx, sourceCard);

    let card = {
        ...sourceCard,
        ...originalCard.data,
    };

    // Conserve actions from the previous version of the card, as it's a counter that changes
    // over time.
    if (sourceCard.actions !== null && typeof(sourceCard.actions) !== 'undefined') {
        card = {
            ...card,
            actions: sourceCard.actions,
        };
    }

    modifiers.forEach(m => {
        const [ modifier, source ] = getModifier(G, ctx, m);
        card = modifier(card, source, G, ctx);
    });

    // Make sure cost is always positive.
    if (card.cost < 0) {
        card.cost = 0;
    }

    return card;
}


export default function updateCards(G, ctx) {
    G.cards = G.cards.map(c => getUpdatedCard(G, ctx, c));
}
