import React from 'react';

import './CardText.css';

import CardTextLine from './CardTextLine';


export default function CardText({ card }) {
    const text = card.text || [];

    const lines = text.map((line, i) => {
        return <CardTextLine key={ i } line={ line } card={ card } />;
    });

    const addLength = (size, line) => {
        const content = line.replace(/:~/g, card.name);
        return size + content.length;
    }
    let length = text.reduce(addLength, 0);
    if (card.helper) {
        length = card.helper.reduce(addLength, length);
    }

    let sizeClass = 'big';
    if (length > 120) {
        sizeClass = 'medium';
    }
    if (length > 160) {
        sizeClass = 'normal';
    }
    if (length > 200) {
        sizeClass = 'small';
    }

    const cls = `card-text ${sizeClass}`;

    return (
        <section className={ cls }>
            { lines }
        </section>
    );
}
