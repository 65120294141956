import { getBehavior } from '../utils';

import activateAbility from './activateAbility';


/**
 * Play an ability of a card at instant speed.
 */
export default function activateInstantAbility(G, ctx, cardID, abilityID) {
    const card = G.cards.find(c => c.id === cardID);
    const behavior = getBehavior(G, ctx, card);
    const ability = behavior.abilities[abilityID];

    if (!ability.instant) {
        return;
    }

    return activateAbility(G, ctx, cardID, abilityID);
}
