import React from 'react';

import './DecreeDeck.css';

import { isLastDecree, getCard } from '../utils';
import stack from '../modules/stack';
import steps from '../modules/steps';

import CardBack from './CardBack';
import CardWithZoom from '../ui/CardWithZoom';


export default function DecreeDeck(props) {
    const { G, ctx, animating, playerID, isActive, owner, setShowProposeDecree } = props;

    let topDecree;

    if (isLastDecree(G, ctx, owner)) {
        topDecree = G.cards.find(c => (
            c.owner === owner
            && c.type === 'Decree'
            && c.zone === null
        ));
    }
    else {
        const topDecreeID = G.public[owner].decreeDeck[0];
        topDecree = getCard(G, ctx, topDecreeID);
    }

    let knownDecreeClass = 'decree-deck';
    let zoomPosition = 'center';
    if (playerID !== owner) {
        knownDecreeClass += ' opponent';
        zoomPosition += ' top';
    }
    else {
        zoomPosition += ' bottom';
    }

    let cls = 'known-decree';
    if (
        isActive
        && !animating
        && !G.conflict.proposedThisTurn
        && ctx.currentPlayer === owner
        && G.steps.current === steps.STEPS.MAIN
        && !ctx.activePlayers
        && stack.isEmpty(G, ctx)
    ) {
        cls += ' active';
    }

    function proposeDecree() {
        if (
            // The player is not currently allowed to play.
            !isActive

            // An animation is running.
            || animating

            // The player is not currently active.
            || ctx.currentPlayer !== playerID

            // The player already proposed a decree this turn.
            || G.conflict.proposedThisTurn
        ) {
            return;
        }

        setShowProposeDecree(true);
    }

    return (
        <div className={ knownDecreeClass }>
            <div className='decree-back'>
                <CardBack />
            </div>
            { !topDecree ? null :
            <div className={ cls } onClick={ proposeDecree }>
                <CardWithZoom
                    card={ topDecree }
                    size='small'
                    zoomPosition={ zoomPosition }
                    animation='slit-in'
                />
            </div>
            }
        </div>
    );
}
