import React from 'react';

import './CardStats.css';

import CONSTANTS from '../constants';


function Actions({ value }) {
    return (
        <>
            <img src='/img/action.svg' alt='actions' />
            { value }
        </>
    );
}


function Boost({ value }) {
    return (
        <>
            <img src='/img/boost.svg' alt='boost' />
            { value }
        </>
    );
}


function Influence({ value }) {
    return (
        <>
            <img src='/img/influence.svg' alt='influence' />
            { value }
        </>
    );
}


function Prestige({ value }) {
    return (
        <>
            <img src='/img/prestige.svg' alt='prestige' />
            { value }
        </>
    );
}


export default function CardStats({ card, isMiniature }) {
    if (card.type === 'Decree') {
        return (
            <section className="card-stats">
                <p className='influence'><Influence value={ card.influence } /></p>
            </section>
        );
    }

    let prestige = null;
    let actions = null;
    let boost = null;

    if (!isMiniature || card.zone !== CONSTANTS.ZONES.BOARD) {
        boost = <Boost value={ card.boost } />;
    }

    if (!isMiniature || card.zone !== CONSTANTS.ZONES.CONFLICT) {
        if (card.type === 'Character') {
            prestige = <Prestige value={ card.prestige } />;
            actions = <Actions value={ card.actions } />;
        }

        if (card.type === 'Craft') {
            actions = <Actions value={ card.actions } />;
        }
    }

    return (
        <section className="card-stats">
            <p className='prestige'>{ prestige }</p>
            <p className='boost'>{ boost }</p>
            <p className='actions'>{ actions }</p>
        </section>
    );
}
