import React from 'react';

import './Ability.css';

import COLORS from '../colors';
import CardText from './CardText';
import CardType from './CardType';


export default function Ability({ card }) {
    if (!card) {
        return 'Card data is missing';
    }

    const levels = (card.levels || '').split('');
    const cardColor = COLORS[levels[0]] || 'colorless';

    return (
        <div className={ `card card-ability color-${cardColor}` }>
            <div className={ `type-${card.type.toLowerCase().replace(' ', '-')}` }>
                <section className="title">
                    <h1>{ card.name }</h1>
                </section>

                <section className="image">
                    <img src={ `/img/illus/${card.illus}.jpg` } alt="" />
                </section>

                <CardType card={ card } />
                <CardText card={ card } />
            </div>
        </div>
    );
}
