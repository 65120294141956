import React from 'react';

import './Library.css';

import CardBack from './CardBack';


export default function Library(props) {
    const { G, owner } = props;

    const librarySize = G.properties[owner].cardCounts.deckSize;

    return (
        <div className='library'>
            <CardBack>
                <p>{ librarySize }</p>
            </CardBack>
        </div>
    );
}
