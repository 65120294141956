export default {
    'b': 'blue',
    'g': 'green',
    'p': 'purple',
    'r': 'red',
    'y': 'yellow',
    'c': 'colorless',
};


export const COLORS_ORDER = {
    'blue': 1,
    'green': 2,
    'purple': 3,
    'red': 4,
    'yellow': 5,
    'colorless': 99,
};
