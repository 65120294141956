import React from 'react';

import './PlayerBoard.css';

import CONSTANTS from '../constants';
import { getItemBehavior } from '../utils';
import steps from '../modules/steps';

import CardOnBoard from './CardOnBoard';


export default function PlayerBoard(props) {
    const { G, ctx, owner, playerID, setShowCardInspector } = props;
    const stage = ctx.activePlayers && ctx.activePlayers[playerID];
    const inConflictPhase = steps.isInConflictPhase(G, ctx);

    const participants = G.conflict.participants[owner];
    const board = G.cards.filter(c => (
        c.controller === owner
        && c.zone === CONSTANTS.ZONES.BOARD
        && c.type === 'Character'
        // Do not include Characters that are engaged in a conflict,
        // as those are already displayed in the ConflictSummary.
        && !(
            inConflictPhase && (
                participants.declared.includes(c.id)
                || participants.current.includes(c.id)
            )
        )
    )).sort(
        (first, second) => first.lastMovedOn > second.lastMovedOn
    );
    const boardCardIDs = board.map(c => c.id);
    const soulshifts = G.cards.filter(c => (
        c.zone === CONSTANTS.ZONES.BOARD
        && boardCardIDs.includes(c.attachedTo)
    ));

    const decreesOnBoard = G.cards.filter(c => (
        c.controller === owner
        && c.zone === CONSTANTS.ZONES.BOARD
        && c.type === 'Decree'
    ));

    function inspectCard(event, card) {
        event.preventDefault();
        setShowCardInspector(card.id);
    }

    // If targeting, find the next target criteria to highlight cards if appropriate.
    let nextTarget = null;
    if (stage === 'target') {
        const item = G.targets.currentItem;
        const behavior = getItemBehavior(G, ctx, item);
        nextTarget = behavior.targets[item.targets.length];
    }

    const cards = board.map((card, index) => <CardOnBoard
        card={ card }
        index={ index }
        owner={ owner }
        board={ board }
        zone='board'
        nextTarget={ nextTarget }
        soulshifts={ soulshifts }
        inspectCard={ inspectCard }
        key={ card.id }
        { ...props }
    />);

    const decrees = decreesOnBoard.map((card, index) => <CardOnBoard
        card={ card }
        index={ index }
        board={ board }
        zone='board'
        nextTarget={ nextTarget }
        soulshifts={ soulshifts }
        inspectCard={ inspectCard }
        key={ card.id }
        { ...props }
    />);

    const boardClass = [
        'player-board',
        (playerID === owner) ? 'you' : 'opponent',
    ].join(' ');

    return (
        <section className={ boardClass }>
            <ul>{ cards }</ul>
            <ul>{ decrees }</ul>
        </section>
    );
}
