export default {
    effects: {
        wait: {
            duration: 1,
        },
        turnStart: {
            create: data => data,
            duration: 1,
        },
        addToStack: {
            create: data => data,
            duration: 0.5,
        },
        resolve: {
            create: data => data,
            duration: 0.5,
        },
        discardCard: {
            create: data => data,
            duration: 0.5,
        },
        drawCard: {
            create: data => data,
            duration: 0.5,
        },
        conflictRevealBoosts: {
            create: data => data,
            duration: 0.7,
        },
        abandonConflict: {
            create: data => data,
            duration: 2,
        },
        winConflict: {
            create: data => data,
            duration: 2,
        },
        loseConflict: {
            create: data => data,
            duration: 2,
        },
    },
};
