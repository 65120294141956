import * as ACTIONS from '../actions';
import CONSTANTS from '../constants';
import MESSENGER from '../messenger';
import {
    getStripedState,
} from '../utils';
import resolveConflict from '../state/resolveConflict';

import targets from './targets';


const STEPS = {
    MAIN: 'steps/MAIN',
    CONFLICT: 'steps/CONFLICT',
    ASSEMBLY: 'steps/ASSEMBLY',
    SACRIFICE: 'steps/SACRIFICE',
};


const STEPS_DATA = {
    [STEPS.MAIN]: {
        name: 'Main',
        next: STEPS.SACRIFICE,
    },
    [STEPS.CONFLICT]: {
        name: 'Pre-Conflict',
        next: STEPS.ASSEMBLY,
    },
    [STEPS.ASSEMBLY]: {
        name: 'Assembly',
        next: STEPS.MAIN,
        onEnd: resolveConflict,
    },
    [STEPS.SACRIFICE]: {
        name: 'Sacrifice',
        next: null,
        onBegin: (G, ctx) => {
            // Kill all permanents with zero actions.
            const permanentsToKill = G.cards.filter(c => (
                c.type === 'Character'
                && c.zone === CONSTANTS.ZONES.BOARD
                && c.actions <= 0
            ));
            permanentsToKill.forEach(c => ACTIONS.sacrifice(G, ctx, c));
        },
    },
};


const START_STEP = STEPS.MAIN;


export function setup(G, ctx) {
    G.steps = {
        current: START_STEP,
    };
    return G;
}


function advance(G, ctx) {
    // Some items require their targets to be set, do that first.
    if (G.stack.requiringTargets.length) {
        const item = G.stack.requiringTargets[G.stack.requiringTargets.length - 1];
        targets.startTargeting(G, ctx, item, true);
    }
    // There are items on the stack, start a priority exchange.
    else if (G.stack.items.length) {
        MESSENGER.publish(MESSENGER.PRIORITY_EXCHANGE, G, ctx);
    }
    // If we're in a player's main step, move out of any stages so that they
    // can start playing non-instant cards or abilities again.
    else if (G.steps.current === STEPS.MAIN && !G.properties[ctx.currentPlayer].endedTurn) {
        ctx.events.endStage();
    }
    // We are in the boost step, let players choose to boost.
    else if (G.steps.current === STEPS.ASSEMBLY && !G.conflict.assemblyFinished) {
        ctx.events.setActivePlayers({
            currentPlayer: 'assembly',
        });
    }
    // In any other case, start a priority exchange.
    else {
        MESSENGER.publish(MESSENGER.PRIORITY_EXCHANGE, G, ctx);
    }
}


/**
 * Move on to the next step.
 *
 * Calls the `onBegin` trigger of the new step if any.
 */
function moveToNextStep(G, ctx) {
    const step = STEPS_DATA[G.steps.current];

    set(G, ctx, step.next);
}


/**
 * Start with the first step at the beginning of a turn.
 */
export function start(G, ctx) {
    G.steps.current = START_STEP;

    if (STEPS_DATA[G.steps.current].onBegin) {
        STEPS_DATA[G.steps.current].onBegin(G, ctx);
    }

    advance(G, ctx);
}


export function set(G, ctx, nextStep) {
    const current = STEPS_DATA[G.steps.current];
    const next = STEPS_DATA[nextStep];

    if (current.onEnd) {
        current.onEnd(G, ctx);
    }


    if (nextStep === null) {
        ctx.effects.turnStart({
            G: getStripedState(G, ctx),
        });
        ctx.events.endTurn();
        return;
    }

    G.steps.current = nextStep;

    if (next && next.onBegin) {
        next.onBegin(G, ctx);
    }

    advance(G, ctx);
}


export function isInConflictPhase(G, ctx) {
    return (
        G.steps.current === STEPS.CONFLICT
        || G.steps.current === STEPS.ASSEMBLY
    );
}


export default {
    STEPS,
    STEPS_DATA,
    setup,
    advance,
    isInConflictPhase,
    moveToNextStep,
    set,
    start,
};
