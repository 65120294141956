import CONSTANTS from '../constants';


let currentId = 0;


export default function create(ctx, playerID, source, cardKey) {
    const cardIndex = source.findIndex(c => c.key === cardKey);

    return {
        ...(source[cardIndex].data),
        id: currentId++,
        original: cardIndex,
        owner: playerID,
        controller: playerID,
        zone: CONSTANTS.ZONES.DECK,
        lastMovedOn: 0,
        revealed: false,
        exhausted: false,
        freshOnBoard: false,
        modifiers: [],
        targets: [],
        attachedTo: null,
    };
}
