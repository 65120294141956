import React from 'react';

import './CardMiniature.css';

import COLORS from '../colors';
import CONSTANTS from '../constants';
import Card from './Card';
import CardStats from './CardStats';


export default function CardMiniature({ card }) {
    if (!card) {
        return 'Card data is missing';
    }

    if (card.zone === CONSTANTS.ZONES.HAND || card.zone === CONSTANTS.ZONES.STACK) {
        return <Card card={ card } />;
    }

    const levels = (card.levels || '').split('');
    const cardColor = COLORS[levels[0]] || 'colorless';

    return (
        <div className={ `card card-miniature color-${cardColor}` }>
            <div className={ `type-${card.type.toLowerCase().replace(' ', '-')}` }>
                <section className="title">
                    <h1>{ card.name }</h1>
                </section>

                <section className="image">
                    <img src={ `/img/illus/${card.illus}.jpg` } alt="" />
                </section>

                <CardStats card={ card } isMiniature={ true } />
            </div>
        </div>
    );
}
