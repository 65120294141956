import React from 'react';

import './CardsList.css';

import CARDS from './data/cards';

import Card from './card/Card';
import Layout from './screens/Layout';


function byInt(key) {
    return (a, b) => a.data[key] - b.data[key];
}

function byString(key) {
    return (a, b) => {
        if (a.data[key] > b.data[key]) {
            return 1;
        }
        if (a.data[key] > b.data[key]) {
            return -1;
        }
        return 0;
    };
}

function sorted(list) {
    list.sort(byString('name')).sort(byInt('cost')).sort(byString('type'));
    return list;
}


export default function CardsList() {
    const finalDecree = CARDS.find(c => c.key === 'XXX-D00');
    const decrees = CARDS.filter(c => c.data.type === 'Decree' && c.key !== 'XXX-D00');
    decrees.sort(byString('name')).sort(byInt('influence'));

    const characters = sorted(CARDS.filter(c => c.data.type === 'Character'));
    const events = sorted(CARDS.filter(c => c.data.type === 'Event'));
    const soulshifts = sorted(CARDS.filter(c => c.data.type === 'Soulshift'));

    return (<Layout>
        <section className='cards-list'>
            <h2>Decrees</h2>
            <ul>
                { decrees.map(c => <li key={ c.key }><Card card={ c.data } /></li> )}
            </ul>
            <h2>Final Decree</h2>
            <ul>
                <li><Card card={ finalDecree.data } /></li>
            </ul>
            <h2>Characters</h2>
            <ul>
                { characters.map(c => <li key={ c.key }><Card card={ c.data } /></li> )}
            </ul>
            <h2>Events</h2>
            <ul>
                { events.map(c => <li key={ c.key }><Card card={ c.data } /></li> )}
            </ul>
            <h2>Soulshifts</h2>
            <ul>
                { soulshifts.map(c => <li key={ c.key }><Card card={ c.data } /></li> )}
            </ul>
        </section>
    </Layout>);
}
