import React from 'react';

import './CardBack.css';


export default function CardBack(props) {
    return (
        <div className='card-back'>
            { props.children }
        </div>
    );
}
