import CONSTANTS from '../constants';
import { getCard, moveToZone } from '../utils';


function findBoost(G, ctx, source) {
    return source.some(id => {
        const card = getCard(G, ctx, id);
        if (card.zone === CONSTANTS.ZONES.CONFLICT) {
            return true;
        }
        return false;
    });
}


/**
 * Add or remove a card to a conflict side.
 */
export default function toggleBoost(G, ctx, cardID) {
    const card = getCard(G, ctx, cardID);
    const participants = G.conflict.participants[ctx.playerID];

    // Look for card in current participants list.
    const index = participants.current.findIndex(c => c === card.id);

    // If card is not already participating, add it.
    if (index === -1) {
        if (
            // Verify card belongs to the player.
            card.owner !== ctx.playerID

            // Verify card is in hand.
            || card.zone !== CONSTANTS.ZONES.HAND

            // Verify card isn't already in participants.
            || participants.declared.includes(card.id)

            // Verify there isn't already a boost card.
            || findBoost(G, ctx, participants.declared)
            || findBoost(G, ctx, participants.current)
        ) {
            return;
        }

        // Move card to conflict zone.
        moveToZone(G, ctx, card, CONSTANTS.ZONES.CONFLICT);

        // Add card to the list of supporters.
        participants.current.push(card.id);
    }
    else {
        // Remove card from the list of supporters.
        participants.current.splice(index, 1);

        // Move card back to hand.
        moveToZone(G, ctx, card, CONSTANTS.ZONES.HAND);
    }
}
