import React from 'react';


export default function CardType({ card }) {
    let subtypes = card.subtypes;
    if (!subtypes || (subtypes.length === 1 && subtypes[0] === '')) {
        subtypes = [];
    }

    let supertypes = card.supertypes;
    if (!supertypes || (supertypes.length === 1 && supertypes[0] === '')) {
        supertypes = [];
    }

    return (
        <section className="type">
            <p>
                { (supertypes.length) ? supertypes.join(' ') + ' ' : '' }
                { card.type }
                { (subtypes.length) ? ' — ' + subtypes.join(' ') : ''}
            </p>
        </section>
    );
}
