import React from 'react';

import './PlayerMetadata.css';

import PlayerAvatar from './PlayerAvatar';
import PlayerEnergy from './PlayerEnergy';
import PlayerInfluence from './PlayerInfluence';


export default function PlayerMetadata(props) {
    return (
        <div className='player-metadata'>
            <PlayerEnergy { ...props } />
            <PlayerAvatar { ...props} />
            <PlayerInfluence { ...props} />
        </div>
    );
}
