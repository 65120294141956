import React from 'react';

import './HomeScreen.css';

import Button from '../ui/Button';


const blockPlay = process.env.REACT_APP_BLOCK_PLAY === 'yes';


function PlayerNameForm(props) {
    const { setPlayerName } = props;

    const [tmpName, setTmpName] = React.useState('Visitor');

    function handleChangeName(event) {
        event.preventDefault();
        setTmpName(event.target.value);
    }

    function handleSubmitName(event) {
        event.preventDefault();
        setPlayerName(tmpName);
    }

    return (
        <form onSubmit={ handleSubmitName }>
            <p>
                <label htmlFor='player-name'>Choose a name:</label>
            </p>
            <p>
                <input
                    id='player-name'
                    name='player-name'
                    value={ tmpName }
                    onChange={ handleChangeName }
                    autoFocus
                />
            </p>
            <p><Button type='submit'>Join</Button></p>
        </form>
    );
}


function JoinMatchForm(props) {
    const { playerName, startLookingForMatch } = props;

    function playGame(event) {
        event.preventDefault();
        startLookingForMatch();
    }

    return (
        <div>
            <p>Welcome, { playerName }!</p>
            <Button onClick={ playGame }>Play</Button>
        </div>
    );
}


function NewsletterForm() {
    return (
        <div>
            <h2>Play is not available at the moment.</h2>
            <p>
                Don't miss the next testing session: <strong>subscribe to our newsletter</strong>!
            </p>
            <form
                action="https://ninja.us2.list-manage.com/subscribe/post?u=380ae3b442e9d40479989e742&amp;id=93b278ec6b"
                method="post"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
            >
                <p>
                    <input
                        type="email"
                        defaultValue=""
                        name="EMAIL"
                        className="email"
                        id="mce-EMAIL"
                        placeholder="email address"
                        required
                    />
                    <Button
                        type="submit"
                        name="subscribe"
                    >
                        Subscribe
                    </Button>
                </p>
                <div
                    style={ { position: 'absolute', left: '-5000px' } }
                    aria-hidden="true"
                >
                    <input
                        type="text"
                        name="b_380ae3b442e9d40479989e742_93b278ec6b"
                        tabIndex="-1"
                        defaultValue=""
                    />
                </div>
            </form>
        </div>
    );
}


export default function HomeScreen(props) {
    const { phase, playerName, startLookingForMatch, setPlayerName } = props;

    let content;
    if (blockPlay) {
        content = <NewsletterForm />;
    }
    else if (phase === 'enter') {
        content = <PlayerNameForm setPlayerName={ setPlayerName } />;
    }
    else {
        content = (
            <JoinMatchForm
                playerName={ playerName }
                startLookingForMatch={ startLookingForMatch }
            />
        );
    }

    return (
        <section className='home-screen content'>
            { content }
        </section>
    );
}
