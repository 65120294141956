import React from 'react';

import './ProposeDecree.css';

import { isLastDecree, getCard } from '../utils';
import Card from '../card/Card';
import Button from '../ui/Button';
import Modal from '../ui/Modal';


export default function ProposeDecree(props) {
    const { G, ctx, moves, playerID, showProposeDecree, setShowProposeDecree } = props;

    if (!showProposeDecree) {
        return null;
    }

    let decree;

    if (isLastDecree(G, ctx, playerID)) {
        decree = G.cards.find(c => (
            c.owner === playerID
            && c.type === 'Decree'
            && c.zone === null
        ));
    }
    else {
        const topDecreeID = G.public[playerID].decreeDeck[0];
        decree = getCard(G, ctx, topDecreeID);
    }

    function cancel() {
        setShowProposeDecree(false);
    }

    function propose() {
        cancel();
        moves.proposeDecree();
    }

    return (
        <Modal cls='propose-decree'>
            <header>
                <h1>Propose a Decree</h1>
                <hr />
            </header>
            <div className='content'>
                <Card card={ decree } />
            </div>
            <div className='options'>
                <Button onClick={ cancel } importance='secondary'>Cancel</Button>
                <Button onClick={ propose }>Propose</Button>
            </div>
        </Modal>
    );
}
