import { createCard, createDecree } from './card';


const AGGRO = {
    deck: [
        'XXX-C02',
        'XXX-C03',
        'XXX-C04',
        'XXX-C05',
        'XXX-C06',
        'XXX-C07',
        'XXX-C09',
        'XXX-C11',
        'XXX-C12',
        'XXX-C13',
        'XXX-C15',
        'XXX-C17',
        'XXX-C23',
        'XXX-C25',
        'XXX-E01',
        'XXX-E03',
        'XXX-E04',
        'XXX-E06',
        'XXX-E08',
        'XXX-S02',
    ],
    decrees: [
        'XXX-D11',
        'XXX-D03',
        'XXX-D04',
        'XXX-D06',
        'XXX-D09',
    ],
};


const CONTROL = {
    deck: [
        'XXX-C08',
        'XXX-C10',
        'XXX-C12',
        'XXX-C14',
        'XXX-C17',
        'XXX-C19',
        'XXX-C21',
        'XXX-C26',
        'XXX-C27',
        'XXX-E02',
        'XXX-E03',
        'XXX-E05',
        'XXX-E06',
        'XXX-E07',
        'XXX-E09',
        'XXX-E12',
        'XXX-E13',
        'XXX-E14',
        'XXX-S01',
        'XXX-S03',
    ],
    decrees: [
        'XXX-D02',
        'XXX-D04',
        'XXX-D06',
        'XXX-D07',
        'XXX-D10',
    ],
};


const MIDRANGE = {
    deck: [
        'XXX-C01',
        'XXX-C07',
        'XXX-C12',
        'XXX-C13',
        'XXX-C15',
        'XXX-C16',
        'XXX-C17',
        'XXX-C18',
        'XXX-C20',
        'XXX-C21',
        'XXX-C22',
        'XXX-C23',
        'XXX-C24',
        'XXX-C25',
        'XXX-C26',
        'XXX-E06',
        'XXX-E07',
        'XXX-E10',
        'XXX-E11',
        'XXX-E15',
    ],
    decrees: [
        'XXX-D01',
        'XXX-D05',
        'XXX-D08',
        'XXX-D09',
        'XXX-D10',
    ],
};


function getDeck(type) {
    switch (type) {
        case 'aggro':
            return AGGRO;
        case 'control':
            return CONTROL;
        default:
            return MIDRANGE;
    }
}


export function createDeck(ctx, playerID, deckType) {
    const cardsList = getDeck(deckType).deck;

    // All cards appear 3 times.
    const deckList = cardsList.concat(cardsList).concat(cardsList);

    return deckList.map(key => createCard(ctx, playerID, key));
}


export function createDecreeDeck(ctx, playerID, deckType) {
    const cardsList = getDeck(deckType).decrees;
    return cardsList.map(key => createDecree(ctx, playerID, key));
}
