import React from 'react';

import './CardInspector.css';

import CONSTANTS from '../constants';
import { getCard } from '../utils';

import Card from '../card/Card';
import Button from '../ui/Button';
import Modal from '../ui/Modal';


export default function CardInspector(props) {
    const { G, ctx, cardID, handleClose } = props;

    let card = getCard(G, ctx, cardID);
    let soulshifts = [];

    if (card) {
        soulshifts = G.cards.filter(c => (
            c.zone === CONSTANTS.ZONES.BOARD
            && c.attachedTo === card.id
        ));
    }
    else {
        // This is not a known card, it must be a stack item.
        const item = G.stack.items.find(i => i.id === cardID);
        card = getCard(G, ctx, item.cardID);

        if (item.type !== 'card') {
            card = {
                ...card,
                zone: CONSTANTS.ZONES.STACK,
                type: 'Ability',
            };
        }
    }

    return (
        <Modal
            cls='card-inspector'
            onContextMenu={ handleClose }
        >
            <div className='content'>
                { soulshifts.map(c => <Card card={ c } />) }
                <Card card={ card } />
            </div>
            <div className='options'>
                <Button onClick={ handleClose }>Done</Button>
            </div>
        </Modal>
    );
}
