import priority from '../modules/priority';


/**
 * End the current player's turn.
 */
export default function finishTurn(G, ctx) {
    G.properties[ctx.currentPlayer].endedTurn = true;
    priority.next(G, ctx);
}
