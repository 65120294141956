const STARTING_ENERGY = 0;
const STARTING_INFLUENCE = 0;
const ADOPTED_DECREES_COUNT_TO_WIN = 4;


const ZONES = {
    DECK: 'zones/DECK',
    HAND: 'zones/HAND',
    BOARD: 'zones/BOARD',
    VOID: 'zones/VOID',
    STACK: 'zones/STACK',
    CONFLICT: 'zones/CONFLICT',
};


const PUBLIC_ZONES = [
    ZONES.BOARD,
    ZONES.VOID,
    ZONES.STACK,
];


export default {
    ADOPTED_DECREES_COUNT_TO_WIN,
    STARTING_ENERGY,
    STARTING_INFLUENCE,
    ZONES,
    PUBLIC_ZONES,
};
