import CONSTANTS from '../constants';
import MESSENGER from '../messenger';
import {
    canPlayAbility,
    getBehavior,
    hasValidTargets,
} from '../utils';

import stack from './stack';
import steps from './steps';
import targets from './targets';


export function setup(G, ctx) {
    G.priority = {
        playersHavingPassed: 0,
    };
    return G;
}


function startTargeting(G, ctx) {
    const item = G.stack.requiringTargets[G.stack.requiringTargets.length - 1];
    targets.startTargeting(G, ctx, item, true);
}


/**
 * Return the order of players for priority exchanges during the current turn.
 *
 * Tests
 * input: ctx.playOrder = ['0', '1', '2', '3'], ctx.playOrderPos = 1
 * output: ['1', '2', '3', '0'];
 *
 * input: ctx.playOrder = ['0', '1', '2', '3'], ctx.playOrderPos = 3
 * output: ['3', '0', '1', '2'];
 */
function getPlayersOrder(G, ctx) {
    return [].concat(
        ctx.playOrder.slice(ctx.playOrderPos, ctx.playOrder.length),
        ctx.playOrder.slice(0, ctx.playOrderPos),
    );
}


/**
 * Return true if a given player is able to perform any actions (cast a card or activate an ability).
 * Returns false otherwise.
 */
function canPlayerDoAnything(G, ctx, playerID) {
    const energy = G.properties[playerID].energy.available;
    const hand = G.cards.filter(c => (
        c.owner === playerID
        && c.zone === CONSTANTS.ZONES.HAND
    ));
    const board = G.cards.filter(c => (
        c.controller === playerID
        && c.zone === CONSTANTS.ZONES.BOARD
    ));

    // Can the player cast a card from their hand?
    const canCast = hand.some(card => (
        card.instant
        && card.cost <= energy
        && hasValidTargets(G, ctx, card, getBehavior(G, ctx, card))
    ));
    if (canCast) {
        return true;
    }

    // Can the player activate an ability of one of their permanents?
    const canActivate = board.some(card => {
        const behavior = getBehavior(G, ctx, card);

        if (!behavior || !behavior.abilities) {
            return false;
        }

        return behavior.abilities.filter(a => a.instant).some(
            ability => canPlayAbility(G, ctx, card, ability)
        );
    });
    if (canActivate) {
        return true;
    }

    return false;
}


function setActivePlayer(G, ctx, player) {
    ctx.events.setActivePlayers({
        value: {
            [player]: 'respond',
        },
    });
}


/**
 * Resolve the top of the stack or move on to the next step if the stack is empty.
 */
 function processNext(G, ctx) {
    G.priority.playersHavingPassed = 0;

    // If there are items on the stack, resolve the next one in line.
    if (G.stack.items.length) {
        stack.resolveStackTop(G, ctx);
        steps.advance(G, ctx);
    }
    // Otherwise, move on to the next step.
    else {
        steps.moveToNextStep(G, ctx);
    }
}


/**
 * Start a new priority exchange.
 */
function start(G, ctx, resetCount) {
    if (resetCount) {
        G.priority.playersHavingPassed = 0;
    }

    if (G.stack.requiringTargets.length) {
        // Some items require their targets to be set, do that first.
        startTargeting(G, ctx);
    }
    else {
        while (G.priority.playersHavingPassed < ctx.playOrder.length) {
            const player = getPlayersOrder(G, ctx)[G.priority.playersHavingPassed];
            if (canPlayerDoAnything(G, ctx, player)) {
                setActivePlayer(G, ctx, player);
                break;
            }
            else {
                G.priority.playersHavingPassed++;
            }
        }

        if (G.priority.playersHavingPassed >= ctx.playOrder.length) {
            // No players can do anything, process the next action.
            processNext(G, ctx);
        }
    }
}


/**
 * Move on in the current step.
 *
 * Increases the count of players having passed. If all have passed, progress to the next step,
 * otherwise pass the priority to the next player.
 */
export function next(G, ctx) {
    G.priority.playersHavingPassed++;
    start(G, ctx);
}


/**
 * Come back to the current step exactly where we were before.
 *
 * This is useful when a user takes a special action (like targeting) but cancels before
 * completing that action.
 */
export function resume(G, ctx) {
    // Some items require their targets to be set, do that first.
    if (G.stack.requiringTargets.length) {
        startTargeting(G, ctx);
    }
    else if (
        G.stack.items.length
        || G.steps.current !== steps.STEPS.MAIN
        || G.priority.playersHavingPassed > 0
    ) {
        start(G, ctx);
    }
}


MESSENGER.subscribe(MESSENGER.PRIORITY_EXCHANGE, start);


export default {
    setup,
    next,
    resume,
    start,
};
