import CONSTANTS from './constants';
import MESSENGER from './messenger';
import {
    getCard,
    moveToZone,
} from './utils';

import create from './data/create';

import stack from './modules/stack';


/**
 * Make a given player draw cards from their library.
 *
 * Default value for `numberOfCards` is 1.
 */
export function draw(G, ctx, playerID, numberOfCards = 1) {
    const deck = G.secret[playerID].deck;
    for (let i = 0; i < numberOfCards; i++) {
        if (deck.length > 0) {
            const cardID = deck.shift();
            moveToZone(G, ctx, getCard(G, ctx, cardID), CONSTANTS.ZONES.HAND);
        }
    }
}


/**
 * Increase a player's influence by a given number.
 */
export function gainInfluence(G, ctx, playerID, influence) {
    G.properties[playerID].influence += influence;
}


/**
 * Decrease a player's influence by a given number.
 */
export function loseInfluence(G, ctx, playerID, influence) {
    G.properties[playerID].influence -= influence;
}


/**
 * Make a given player gain energy.
 */
export function gainEnergy(G, ctx, playerID, numberOfEnergies) {
    G.properties[playerID].energy.maximum += numberOfEnergies;
    G.properties[playerID].energy.available += numberOfEnergies;

    MESSENGER.publish(MESSENGER.TRIGGER, G, ctx, 'INCREASE_ENERGY_POOL', { playerID });
}


/**
 * Add a given number of actions to a permanent.
 */
export function gainActions(G, ctx, card, actions) {
    card.actions += actions;
}


/**
 * Remove a given number of actions from a permanent.
 */
export function loseActions(G, ctx, card, actions) {
    card.actions -= actions;
    if (card.actions < 0) {
        card.actions = 0;
    }
}


/**
 * Destroy any given permanent on the board.
 *
 * Finds the permanent by its ID, remove it from its current controller's board then
 * move it to its owner's void.
 */
export function destroy(G, ctx, target) {
    // You cannot destroy something that is not on the board.
    if (target.zone !== CONSTANTS.ZONES.BOARD) {
        return;
    }

    // Move target from board to owner's void.
    moveToZone(G, ctx, target, CONSTANTS.ZONES.VOID);
}


/**
 * Sacrifice a given permanent on the board.
 *
 * Currently just an alias for `destroy` but eventually these two should behave slightly
 * differently.
 */
export function sacrifice(G, ctx, target) {
    destroy(G, ctx, target);
}


/**
 * Remove an item from the stack.
 *
 * If the item was a card (as opposed to an ability), moves it to its owner's void.
 */
export function counter(G, ctx, itemID) {
    // Remove item from the stack.
    const item = stack.remove(G, ctx, itemID);

    if (item && item.type === 'Card') {
        moveToZone(G, ctx, getCard(G, ctx, item.cardID), CONSTANTS.ZONES.VOID);
    }
}


/**
 * Create a copy of a card.
 */
export function createToken(G, ctx, controller, allCards, cardKey) {
    // Create a copy of the card.
    const card = create(ctx, controller, allCards, cardKey);

    // Register it in the list of cards.
    G.cards.push(card);

    // Put it on the board.
    card.zone = CONSTANTS.ZONES.BOARD;

    if (card.type === 'Character') {
        MESSENGER.publish(MESSENGER.TRIGGER, G, ctx, 'CHARACTER_IS_SUMMONED', { triggeredByID: card.id });
    }
}
