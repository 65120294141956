import React from 'react';
import { Link } from 'react-router-dom';

import './Layout.css';


export default function Layout({ children, noMenu }) {
    return (
        <main id='layout'>
            <header>
                <h1>Souls</h1>
                { noMenu ? null :
                <nav>
                    <ul>
                        <li><Link to='/'>Lobby</Link></li>
                        <li><Link to='/rules'>How to Play</Link></li>
                        <li><Link to='/cards'>All Cards</Link></li>
                    </ul>
                </nav> }
            </header>
            { children }
        </main>
    );
}
