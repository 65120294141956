/**
 * Pub/Sub messaging pattern implementation.
 *
 * This messenger allows modules to subscribe to events, and run functions when
 * those happen. Other modules will publish those events when appropriate.
 *
 * Event types are defined as constants in the messenger, to avoid typing
 * errors and to ease autocompletion.
 *
 * Usage:
 *   - To subscribe to a given event:
 *   > MESSENGER.subscribe(MESSENGER.EVENT_TYPE, callback);
 *
 *   - To publish an event:
 *   > MESSENGER.publish(MESSENGER.EVENT_TYPE, param1, param2, ...);
 *   (This will call the `callback` function with all parameters except the
 *   event type)
 */

const subscriptions = [];


function subscribe(type, callback) {
    subscriptions.push({
        type,
        callback,
    });
}


function publish(type, ...payload) {
    subscriptions
        .filter(sub => sub.type === type)
        .forEach(sub => sub.callback(...payload));
}


export default {
    subscribe,
    publish,

    // Message types
    TRIGGER: 'message/TRIGGER',
    REGISTER_TRIGGERS: 'message/REGISTER_TRIGGERS',
    PRIORITY_EXCHANGE: 'message/PRIORITY_EXCHANGE',
};
