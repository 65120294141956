import React from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import CONSTANTS from '../constants';
import Card from '../card/Card';
import Button from '../ui/Button';
import Modal from '../ui/Modal';


export default function VoidExplorer(props) {
    const { G, owner, playerID, handleClose } = props;

    const void_ = G.cards.filter(c => (
        c.zone === CONSTANTS.ZONES.VOID
        && c.owner === owner
    )).sort(
        (first, second) => first.lastMovedOn > second.lastMovedOn
    );

    const cards = void_.map(
        (card, index) => <Card card={ card } key={ index } />
    );

    const voidOwner = (owner === playerID) ? 'Your Void' : "Opponent's Void";

    return (
        <Modal cls='void-explorer' onContextMenu={ handleClose }>
            <header>
                <h1>{ voidOwner }</h1>
                <hr />
            </header>
            <div className='content'>
                <ScrollMenu
                    data={ cards }
                />
            </div>
            <div className='options'>
                <Button onClick={ handleClose }>Done</Button>
            </div>
        </Modal>
    );
}
