import * as ACTIONS from '../actions';
import CONSTANTS from '../constants';
import MESSENGER from '../messenger';

import priority from '../modules/priority';
import steps from '../modules/steps';
import { getCard, getStripedState, moveToZone } from '../utils';


export default function validate(G, ctx) {
    const opponentId = ctx.playOrder.find(p => p !== ctx.playerID);
    const participants = G.conflict.participants[ctx.playerID];

    // Set the current assembly.
    if (participants.current.length) {
        // Remove an action from each Character and mark them as exhausted.
        participants.current.forEach(id => {
            const card = getCard(G, ctx, id);
            if (card.zone === CONSTANTS.ZONES.BOARD) {
                card.actions--;
                card.exhausted = true;
            }
        });

        // Merge the next cards into the actual source.
        participants.declared.push(...(participants.current));
        participants.current.length = 0;

        // Let the other player react.
        ctx.events.setActivePlayers({
            value: {
                [opponentId]: 'assembly',
            },
        });
    }
    // Abandon the decree.
    else if (ctx.playerID === ctx.currentPlayer && participants.declared.length === 0) {
        ctx.effects.abandonConflict({
            G: getStripedState(G, ctx),
        });

        // The player abandons his decree, and loses one influence.
        ACTIONS.loseInfluence(G, ctx, ctx.playerID, 1);

        const decree = G.cards.find(c => (
            c.type === 'Decree'
            && c.zone === CONSTANTS.ZONES.CONFLICT
        ));

        // Put decree back in the decree deck.
        moveToZone(G, ctx, decree, CONSTANTS.ZONES.DECK);

        // Move on to the next step.
        ctx.events.endStage();
        steps.set(G, ctx, steps.STEPS.MAIN);
    }
    // Validate the assemblies.
    else {
        // Move on to resolve the conflict.
        // Reveal boost cards.
        const cards = G.cards.filter(c => (
            c.zone === CONSTANTS.ZONES.CONFLICT
            && c.type !== 'Decree'
        ));
        cards.forEach(c => c.revealed = true);

        if (cards.length) {
            ctx.effects.conflictRevealBoosts({
                G: getStripedState(G, ctx),
                cards: JSON.parse(JSON.stringify(cards)),
            });
        }

        // Trigger a boost use for each card.
        cards.forEach(c => {
            MESSENGER.publish(MESSENGER.TRIGGER, G, ctx, 'BOOST_WITH_CARD', { triggeredByID: c.id });
        });

        // Mark that the assembly step is finished, to avoid giving the option again.
        G.conflict.assemblyFinished = true;

        // Move on to the next step.
        ctx.events.endStage();
        priority.start(G, ctx);
    }
}
