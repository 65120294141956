import React from 'react';

import './CardOnBoard.css'

import CONSTANTS from '../constants';
import { canPlayAbility, getBehavior } from '../utils';
import steps from '../modules/steps';

import CardWithZoom from '../ui/CardWithZoom';


export default function CardOnBoard({
    G,
    ctx,
    animating,
    card,
    index,
    isActive,
    playerID,
    moves,
    owner,
    board,
    zone,
    nextTarget,
    soulshifts,
    inspectCard,
    showTargetsForItem,
}) {
    const stage = ctx.activePlayers && ctx.activePlayers[playerID];

    function cardClickAction(e) {
        e.preventDefault();

        if (!isActive || animating) {
            return;
        }

        if (!stage || stage === 'respond') {
            // Player is in their main phase or a respond stage,
            // look if the card has an ability to play.
            const behavior = getBehavior(G, ctx, card);
            if (behavior && behavior.abilities && behavior.abilities.length) {
                moves.activateAbility(card.id, 0);
            }
        }
        else {
            // Player is in a specific stage,
            // look for which one and act accordingly.
            if (stage === 'target') {
                moves.targetPermanent(card);
            }
            else if (stage === 'assembly') {
                if (card.zone === CONSTANTS.ZONES.CONFLICT) {
                    moves.toggleBoost(card.id);
                }
                else {
                    moves.toggleCharacter(card.id);
                }
            }
        }
    }

    // Set card state.
    const attrs = ['card-on-board'];

    // The card is exhausted.
    if (owner !== playerID) {
        attrs.push('opponent');
    }

    // The card is exhausted.
    if (card.exhausted) {
        attrs.push('exhausted');
    }

    // The card can be targeted by an element being played.
    if (
        isActive
        && stage === 'target'
        && nextTarget.types.includes(card.type)
        && (
            !nextTarget.condition
            || nextTarget.condition(G, ctx, { item: G.targets.currentItem, targetID: card.id })
        )
        && (!nextTarget.unique || !G.targets.currentItem.targets.includes(card.id))
    ) {
        attrs.push('targetable');
    }

    // The card is the target of an element of the stack.
    if (showTargetsForItem) {
        const item = G.stack.items.find(i => i.id === showTargetsForItem);
        if (item && item.targets.includes(card.id)) {
            attrs.push('targeted');
        }
    }

    const behavior = getBehavior(G, ctx, card);
    // The card has an ability and it can be played.
    if (
        isActive
        && !animating
        && card.controller === playerID
        && (
            behavior && behavior.abilities && behavior.abilities.length
            && (!stage || (stage === 'respond' && behavior.abilities[0].instant))
            && canPlayAbility(G, ctx, card, behavior.abilities[0])
        )
    ) {
        attrs.push('activable');
    }

    // The player is in the conflict phase and the card can be used.
    const participants = G.conflict.participants[playerID];
    if (
        isActive
        && stage === 'assembly'
        && card.controller === playerID
        && card.type !== 'Decree'
        && (card.controller !== ctx.currentPlayer || !card.freshOnBoard)
        && (
            card.type !== 'Character'
            || (!card.exhausted && card.actions > 0)
        )
        && !participants.declared.includes(card.id)
    ) {
        attrs.push('conflictable');
    }

    const attachments = soulshifts.filter(s => s.attachedTo === card.id);
    if (attachments.length) {
        attrs.push('has-attachments');
    }

    let zoomPosition = [];
    if (card.type === 'Decree') {
        // Decrees are always on the right of the board.
        zoomPosition.push('left');
    }
    else if (zone === 'conflict') {
        // In the conflict zone, cards are aligned on the right.
        zoomPosition.push((index < board.length - 3) ? 'right' : 'left');
    }
    else {
        // Otherwise, assume that cards are centered.
        zoomPosition.push((index < board.length / 2) ? 'right' : 'left');
    }
    zoomPosition.push((owner !== playerID) ? 'top' : 'bottom');
    zoomPosition = zoomPosition.join(' ');

    const size = steps.isInConflictPhase(G, ctx) ? 'small' : 'medium';

    return (
        <li
            className={ attrs.join(' ') }
            key={ card.id }
            onClick={ cardClickAction }
            onContextMenu={ (e) => inspectCard(e, card) }
        >
            <div className='attachments'>
                { attachments.map(a => <CardWithZoom
                    card={ a }
                    size={ size }
                    zoomPosition={ zoomPosition }
                    animation='slit-in'
                    key={ a.id }
                />) }
            </div>
            <CardWithZoom
                card={ card }
                size={ size }
                zoomPosition={ zoomPosition }
                animation='slit-in'
            />
        </li>
    );
}
