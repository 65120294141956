import React from 'react';

import './Hand.css';

import CONSTANTS from '../constants';
import { getBehavior, hasValidTargets } from '../utils';

import CardBack from './CardBack';
import CardWithZoom from '../ui/CardWithZoom';


export default function Hand(props) {
    const { ctx, animating, owner, moves, isActive, playerID, G } = props;
    let cards = [];

    if (owner !== playerID) {
        // Show the opponent's hand with only card backs.
        const cardCounts = G.properties[owner].cardCounts;

        for (let i = 0; i < cardCounts.handSize; i++) {
            cards.push(<li key={ i }><CardBack /></li>);
        }
    }
    else {
        // Show the player's hand.
        const hand = G.cards.filter(
            c => c.zone === CONSTANTS.ZONES.HAND && c.owner === playerID
        ).sort(
            (first, second) => first.lastMovedOn > second.lastMovedOn
        );
        const energy = G.properties[playerID].energy.available;
        let stage = 'main';
        if (ctx.activePlayers && ctx.activePlayers[playerID]) {
            stage = ctx.activePlayers[playerID];
        }

        function cardClickAction(card) {
            if (!isActive || animating) {
                return;
            }

            // If the player is in the boost stage, boost with the clicked card.
            if (stage === 'assembly') {
                moves.toggleBoost(card.id);
            }
            // Otherwise, play the clicked card.
            else {
                moves.playCard(card.id);
            }
        }

        const cardsInConflict = G.cards.filter(c => (
            c.zone === CONSTANTS.ZONES.CONFLICT
            && c.owner === owner
            && c.type !== 'Decree'
        ));

        cards = hand.map((card, i) => {
            const attrs = [];

            if (
                isActive
                && !animating
                && (
                    stage === 'main' ||
                    (stage === 'respond' && card.instant)
                )
                && card.cost <= energy
                && hasValidTargets(G, ctx, card, getBehavior(G, ctx, card))
            ) {
                attrs.push('playable');
            }

            if (
                isActive
                && stage === 'assembly'
                && cardsInConflict.length === 0
            ) {
                attrs.push('conflictable');
            }

            const cardClass = attrs.join(' ');

            const zoomPosition = [
                'center',
                (owner === playerID) ? 'bottom' : 'top',
            ].join(' ');

            return (
                <li
                    className={ cardClass }
                    key={ card.id }
                    onClick={ () => cardClickAction(card) }
                >
                    <CardWithZoom
                        card={ card }
                        zoomPosition={ zoomPosition }
                    />
                </li>
            );
        });
    }

    let className = 'hand';
    if (owner !== playerID) {
        className += ' opponent';
    }

    return <ul className={ className }>
        { cards }
    </ul>;
}
