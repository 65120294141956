import React from 'react';

import './Button.css';


export default function Button({ onClick, type, importance, disabled, children }) {
    let cls = 'button';
    if (importance) {
        cls += ' ' + importance;
    }

    if (!type) {
        type = 'button';
    }

    return (
        <button
            onClick={ onClick }
            type={ type }
            disabled={ disabled }
            className={ cls }
        >
            { children }
        </button>
    );
}
