import React from 'react';
import { useEffectListener } from 'bgio-effects/react';

import './Mulligan.css';

import CONSTANTS from '../constants';
import Card from '../card/Card';
import Button from '../ui/Button';
import Modal from '../ui/Modal';


export default function Mulligan(props) {
    const { G, ctx, moves, playerID } = props;

    const [ selectedCards, setSelectedCards ] = React.useState([]);
    const [ discarded, setDiscarded ] = React.useState([]);
    const [ drawn, setDrawn ] = React.useState([]);

    useEffectListener(
        'discardCard',
        ({ cardID, owner }) => {
            if (owner !== playerID) {
                return;
            }
            setDiscarded(prevVal => [ ...prevVal, cardID ]);
        },
        [ playerID, setDiscarded ]
    );

    useEffectListener(
        'drawCard',
        ({ cardID, owner }) => {
            if (owner !== playerID) {
                return;
            }
            setDrawn(prevVal => [ ...prevVal, cardID ]);
        },
        [ setDrawn ]
    );

    if (G.properties[playerID].readyToPlay) {
        return null;
    }

    // Handle card selection.
    function createToggleCard(cardID) {
        return () => {
            setSelectedCards(prevCards => {
                if (prevCards.includes(cardID)) {
                    return prevCards.filter(c => c !== cardID);
                }
                return [ ...prevCards, cardID ];
            });
        };
    }

    function shuffleCards() {
        moves.mulligan(selectedCards);
    }

    let btnLabel = 'Keep these';
    if (selectedCards.length) {
        btnLabel = `Shuffle ${selectedCards.length} card${selectedCards.length === 1 ? '' : 's'}`;
    }

    // Show cards.
    const hand = G.cards.filter(c => (
        c.zone === CONSTANTS.ZONES.HAND
        && c.owner === playerID
    ));

    let cards = hand
    // Remove cards that have been discarded.
    .filter(
        c => !(drawn.length && discarded.includes(c.id))
    )
    // Sort cards by their order of arrival in the hand.
    .sort(
        (a, b) => a.lastMovedOn > b.lastMovedOn
    )
    // Return actual DOM elements.
    .map(card => {
        let cls = '';
        if (selectedCards.includes(card.id)) {
            cls = 'selected';
        }
        if (discarded.includes(card.id)) {
            cls = 'discarded';
        }

        return (
            <li
                className={ cls }
                onClick={ createToggleCard(card.id) }
                key={ card.id }
            >
                <Card card={ card } />
            </li>
        );
    });

    // Add cards that have been drawn after the mulligan.
    const drawnCards = G.cards.filter(c => drawn.includes(c.id));
    cards = cards.concat(drawnCards.map(card => (
        <li className='drawn' key={ card.id }>
            <Card card={ card } />
        </li>
    )));

    const goingFirst = (playerID === ctx.currentPlayer);

    return (
        <Modal cls='mulligan'>
            <header>
                <h1>
                    { goingFirst ?
                        'You go first' :
                        ' Opponent goes first'
                    }
                </h1>
                <hr />
                <p>
                    Choose any number of cards to send back to your deck.
                    You will then draw to have 3 cards.
                </p>
            </header>
            <ul className='content'>
                { cards }
            </ul>
            <div className='options'>
                <Button onClick={ shuffleCards }>{ btnLabel }</Button>
            </div>
        </Modal>
    );
}
