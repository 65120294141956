import React from 'react';

import './GameOverScreen.css';

import Button from '../ui/Button';


export default function GameOverScreen(props) {
    const { gameover, playerID, exitMatch } = props;
    const victorious = gameover.winner === playerID;

    let content = 'Defeat…';
    if (victorious) {
        content = 'Victory!';
    }

    return (
        <section className='gameover'>
            <h1>{ content }</h1>
            <p>
                { exitMatch ?
                <Button onClick={ exitMatch }>Leave</Button>
                :
                <span>Waiting for the server…</span>
                }
            </p>
        </section>
    );
}
