import React from 'react';

import './Energy.css';


export default function Energy(props) {
    return <span className={ 'energy' }>
        <span>{ props.value }</span>
    </span>;
}
