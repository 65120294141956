import * as ACTIONS from '../actions';
import CONSTANTS from '../constants';
import {
    isLastDecree,
    getCard,
    getStripedState,
    moveToZone,
} from '../utils';


const computePrestige = (acc, card) => {
    const value = (card.zone === CONSTANTS.ZONES.CONFLICT) ? card.boost : card.prestige;
    return acc + Math.max(value, 0);
};


function getParticipants(G, ctx, player) {
    const participants = G.conflict.participants[player].declared;
    return participants.map(id => getCard(G, ctx, id))
}


function resetConflict(G, ctx, adopted) {
    // Move boost cards to the void.
    G.cards
        .filter(c => c.zone === CONSTANTS.ZONES.CONFLICT)
        .forEach(c => moveToZone(G, ctx, c, CONSTANTS.ZONES.VOID));

    // Reset conflict data.
    const participants = {};
    ctx.playOrder.forEach(player => {
        participants[player] = {
            declared: [],
            current: [],
        };
    });
    G.conflict = {
        proposedThisTurn: true,
        adoptedThisTurn: adopted,
        participants,
        assemblyFinished: false,
    };
}


export default function resolveConflict(G, ctx) {
    const decree = G.cards.find(c => (
        c.type === 'Decree'
        && c.zone === CONSTANTS.ZONES.CONFLICT
    ));

    if (!decree) {
        // This conflict has been abandoned, do nothing.
        resetConflict(G, ctx, false);
        return;
    }

    const supporterID = ctx.currentPlayer;
    const objectorID = ctx.playOrder.find(p => p !== ctx.currentPlayer);

    // Find supporters and objectors cards on their respective boards.
    const supporters = getParticipants(G, ctx, supporterID);
    const objectors = getParticipants(G, ctx, objectorID);

    // Compute the influence of each side.
    const supporterPrestige = supporters.reduce(computePrestige, 0);
    const objectorPrestige = objectors.reduce(computePrestige, 0);

    // Resolve the conflict.
    const total = (
        G.properties[supporterID].influence
        + supporterPrestige
        - G.properties[objectorID].influence
        - objectorPrestige
    );
    const adopted = total >= decree.influence;

    if (adopted) {
        ctx.effects.winConflict({
            G: getStripedState(G, ctx),
        });

        // Resolve the decree.
        moveToZone(G, ctx, decree, CONSTANTS.ZONES.BOARD);

        // Give the objector +1 influence.
        ACTIONS.gainInfluence(G, ctx, objectorID, 1);
    }
    else {
        ctx.effects.loseConflict({
            G: getStripedState(G, ctx),
        });

        if (isLastDecree(G, ctx, ctx.currentPlayer)) {
            moveToZone(G, ctx, decree, null);
        }
        else {
            // Put decree on the bottom of the decree deck.
            moveToZone(G, ctx, decree, CONSTANTS.ZONES.DECK);
            G.secret[ctx.currentPlayer].decreeDeck.push(decree.id);
        }
    }

    resetConflict(G, ctx, adopted);
}
