import {
    getCard,
    getItemBehavior,
} from '../utils';


export function setup(G, ctx) {
    G.targets = {
        currentItem: null,
        mayCancel: true,
    };
    return G;
}


export function cancel(G, ctx) {
    G = setup(G, ctx);
}


export function startTargeting(G, ctx, item, cancelForbidden) {
    const card = getCard(G, ctx, item.cardID);
    G.targets.currentItem = item;
    G.targets.mayCancel = !cancelForbidden;

    ctx.events.setActivePlayers({ value: { [card.controller]: 'target' } });
}


/**
 * Set a target for the current item.
 *
 * Makes sure the target is valid before adding it to the list.
 * If all targets are selected, return true. Otherwise, return false.
 */
export function setTarget(G, ctx, target, type) {
    const item = G.targets.currentItem;

    const behavior = getItemBehavior(G, ctx, item);
    const nextTarget = behavior.targets[item.targets.length];

    // Make sure the target is of the right type.
    if (!nextTarget.types.includes(type)) {
        return false;
    }

    // If targets must be unique, make sure this target isn't already targeted.
    if (nextTarget.unique && item.targets.includes(target)) {
        return false;
    }

    // If the target has a special condition, make sure it is valid.
    if (nextTarget.condition && !nextTarget.condition(G, ctx, { item, targetID: target })) {
        return false;
    }

    // Save target on card being played.
    item.targets.push(target);

    if (item.targets.length === behavior.targets.length) {
        return true;
    }

    return false;
}


export default {
    setup,
    startTargeting,
    setTarget,
    cancel,
};
