import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';

import CardsList from './CardsList';
import Game from './Game';
import RulesGuide from './RulesGuide';


export default function App() {
    return (
        <Router>
            <Switch>
                <Route path='/rules'>
                    <RulesGuide />
                </Route>
                <Route path='/cards'>
                    <CardsList />
                </Route>
                <Route path='/'>
                    <Game />
                </Route>
            </Switch>
        </Router>
    );
}
