import React from 'react';

import './PlayerInfluence.css';


export default function PlayerInfluence(props) {
    const { G, owner } = props;
    const influence = G.properties[owner].influence;

    return <div className='player-influence'>
        <p className='desc'>Influence</p>
        <p className='influence'>{ influence }</p>
    </div>;
}
