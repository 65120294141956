import CONSTANTS from '../constants';
import MESSENGER from '../messenger';
import { getBehavior, getCard, moveToZone } from '../utils';

import priority from './priority';
import stack from './stack';


export function castActivatedAbility(G, ctx, item) {
    // Find actual card on board.
    const card = getCard(G, ctx, item.cardID);
    const behavior = getBehavior(G, ctx, card);
    const ability = behavior.abilities[item.ability];

    // Pay costs.
    if (ability.cost.actions) {
        card.actions -= ability.cost.actions;

        // Exhaust permanent.
        card.exhausted = true;
    }
    if (ability.cost.energy) {
        G.properties[card.owner].energy.available -= ability.cost.energy;
    }

    // Put ability on stack.
    stack.add(G, ctx, item);

    priority.start(G, ctx, true);
}


export function castCard(G, ctx, item) {
    const card = G.cards.find(c => c.id === item.cardID);

    // Pay energy.
    G.properties[card.controller].energy.available -= card.cost;

    // Move card to the stack.
    moveToZone(G, ctx, card, CONSTANTS.ZONES.STACK);
    stack.add(G, ctx, item);

    if (card.type === 'Soulshift') {
        MESSENGER.publish(MESSENGER.TRIGGER, G, ctx, 'SOULSHIFT_IS_CAST', { triggeredByID: card.id });
    }
    if (card.type === 'Event') {
        MESSENGER.publish(MESSENGER.TRIGGER, G, ctx, 'EVENT_IS_CAST', { triggeredByID: card.id });
    }

    priority.start(G, ctx, true);
}


export function castElement(G, ctx, item) {
    // Triggers are put directly on the stack, before setting targets.
    // Thus there is no need to "cast" a trigger.
    if (item.type === 'Ability' && item.genre === 'triggered') {
        return;
    }

    if (item.type === 'Ability' && item.genre === 'activated') {
        castActivatedAbility(G, ctx, item);
    }
    else if (item.type === 'Card') {
        castCard(G, ctx, item);
    }
    else {
        throw new Error(`Unsupported item type: ${item.type} or genre: ${item.genre}`);
    }
}


export default {
    castActivatedAbility,
    castCard,
    castElement,
};
