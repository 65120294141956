import React from 'react';
import { useEffectListener } from 'bgio-effects/react';

import './Stack.css';

import CONSTANTS from '../constants';
import { getCard } from '../utils';
import Card from '../card/Card';


export default function Stack(props) {
    const {
        G,
        ctx,
        isActive,
        moves,
        playerID,
        setShowCardInspector,
        setShowTargetsForItem,
        setForceTargetsForItem,
    } = props;

    const [ addedToStack, setAddedToStack ] = React.useState(null);
    const [ resolveItem, setResolveItem ] = React.useState(null);

    useEffectListener(
        'addToStack',
        ({ itemID }) => {
            setResolveItem(null);
            setAddedToStack(itemID);
        },
        [ setAddedToStack, setResolveItem ]
    );

    useEffectListener(
        'resolve',
        ({ itemID }) => {
            setAddedToStack(null);
            setResolveItem(itemID);
        },
        [ setAddedToStack, setResolveItem ]
    );

    useEffectListener(
        'effects:end',
        () => {
            setAddedToStack(null);
            setResolveItem(null);
        },
        [ setAddedToStack, setResolveItem ]
    );

    function itemClickAction(item) {
        if (!isActive) {
            return;
        }

        if (
            ctx.activePlayers
            && ctx.activePlayers[playerID] === 'target'
        ) {
            if (item.type === 'Card') {
                moves.targetCard(item.id);
            }
            else {
                moves.targetAbility(item.id);
            }
        }
    }

    function setHoverItem(item) {
        setShowTargetsForItem(item.id);
        setForceTargetsForItem(true);
    }

    function clearHoverItem() {
        setForceTargetsForItem(false);
    }

    function inspectCard(event, item) {
        event.preventDefault();
        setShowCardInspector(item.id);
    }

    const items = G.stack.items.map(item => {
        let card = getCard(G, ctx, item.cardID);

        if (item.type !== 'Card') {
            card = {
                ...card,
                zone: CONSTANTS.ZONES.STACK,
                type: 'Ability',
            };
        }

        const attrs = [];
        if (item.id === addedToStack) {
            attrs.push('add-to-stack');
        }
        if (item.id === resolveItem) {
            attrs.push('resolve-item');
        }
        if (card.controller !== playerID) {
            attrs.push('opponent');
        }
        const cls = attrs.join(' ');

        return (
            <li
                key={ item.id }
                className={ cls }
                onClick={ () => itemClickAction(item) }
                onContextMenu={ (e) => inspectCard(e, item) }
                onMouseEnter={ () => setHoverItem(item) }
                onMouseLeave={ clearHoverItem }
            >
                <Card card={ card } />
            </li>
        );
    });

    const requiringTargets = G.stack.requiringTargets.map(item => {
        let card = getCard(G, ctx, item.cardID);

        if (item.type !== 'card') {
            card = {
                ...card,
                type: 'Ability',
            };
        }

        return (
            <li
                onContextMenu={ (e) => inspectCard(e, item) }
                key={ item.id }
            >
                <Card card={ card } />
            </li>
        );
    });

    return (
        <section className="stack">
            <ul className="items">
                { items }
            </ul>
            <ul className="requiringTargets">
                { requiringTargets }
            </ul>
        </section>
    );
}
