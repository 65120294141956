import React from 'react';

import Souls from './Souls';

import ErrorScreen from './screens/ErrorScreen';
import GameOverScreen from './screens/GameOverScreen';
import HomeScreen from './screens/HomeScreen';
import Layout from './screens/Layout';
import WaitingScreen from './screens/WaitingScreen';


export default function GameRenderer(props) {
    const {
        errorMsg,
        phase,
        matches,
        playerName,
        runningMatch,
        handleEnterLobby,
        handleCreateMatch,
        handleExitMatch,
        handleLeaveMatch,
        handleJoinMatch,
        handleStartMatch,
    } = props;

    const [lookingForMatch, setLookingForMatch] = React.useState(false);
    const [joinedMatch, setJoinedMatch] = React.useState(null);

    React.useEffect(() => {
        async function lookForMatch() {
            if (lookingForMatch) {
                // Player hasn't joined a match yet.
                if (!joinedMatch) {
                    // If the player is already in a match, show that they have already joined.
                    const inMatch = matches.find(m => m.players.some(p => p.name === playerName));
                    if (inMatch) {
                        setJoinedMatch(inMatch.matchID);
                        return;
                    }

                    // Look for a match to join.
                    let foundMatch = false;
                    matches.filter(m => !m.gameover).forEach(match => {
                        match.players.forEach(async (player) => {
                            if (!foundMatch && !player.name) {
                                foundMatch = true;
                                await handleJoinMatch(Souls.name, match.matchID, '' + player.id);
                                setJoinedMatch(match.matchID);
                            }
                        });
                    });

                    // No available seats found, create a new match.
                    if (!foundMatch) {
                        await handleCreateMatch(Souls.name, 2);
                    }
                }
                // Player already is in a match.
                else {
                    const match = matches.find(match => match.matchID === joinedMatch);
                    if (!match) {
                        console.warn(`joined match ${joinedMatch} but cannot be found in list`);
                        return;
                    }
                    // When a player joins a match, their `name` is set.
                    if (match.players.every(p => p.name)) {
                        // All seats are filled, game is ready to start.
                        await handleStartMatch(Souls.name, {
                            matchID: match.matchID,
                            playerID: '' + match.players.find(p => p.name === playerName).id,
                            numPlayers: match.players.length,
                        });
                        setLookingForMatch(false);
                    }
                }
            }
        }
        lookForMatch();
    }, [
        playerName, lookingForMatch, joinedMatch, matches,
        handleCreateMatch, handleJoinMatch, handleStartMatch, setJoinedMatch,
    ]);

    function startLookingForMatch() {
        setLookingForMatch(true);
    }

    async function exitMatch() {
        setJoinedMatch(null);
        await handleLeaveMatch(Souls.name, joinedMatch);
        await handleExitMatch();
    }

    async function cancelMatch() {
        setLookingForMatch(false);
        await exitMatch();
    }

    if (errorMsg) {
        return (
            <Layout>
                <ErrorScreen
                    errorMsg={ errorMsg }
                />
            </Layout>
        );
    }

    if (lookingForMatch) {
        return <Layout noMenu={ true }><WaitingScreen cancelMatch={ cancelMatch } /></Layout>;
    }

    if (runningMatch) {
        const match = matches.find(m => m.matchID === joinedMatch);

        if (match && match.gameover) {
            return <GameOverScreen
                gameover={ match.gameover }
                playerID={ runningMatch.playerID }
                exitMatch={ exitMatch }
            />;
        }

        return (
            <runningMatch.app
                matchID={ runningMatch.matchID }
                playerID={ runningMatch.playerID }
                credentials={ runningMatch.credentials }
            />
        );
    }

    return <Layout><HomeScreen
        phase={ phase }
        playerName={ playerName }
        startLookingForMatch={ startLookingForMatch }
        setPlayerName={ handleEnterLobby }
    /></Layout>;
}
