import React from 'react';
import { useEffectListener } from 'bgio-effects/react';

import './PlayerTurnAnimation.css';


export default function PlayerTurnAnimation(props) {
    const { ctx, playerID } = props;
    const [ animate, setAnimate ] = React.useState(false);

    useEffectListener(
        'turnStart',
        (payload) => {
            setAnimate(true);
            const timeout = window.setTimeout(() => setAnimate(false), 1000);
            return () => window.clearTimeout(timeout);
        },
        [ setAnimate ]
    );

    if (!animate) {
        return null;
    }

    let cls = 'player-turn-animation';
    if (animate) {
        cls += ' animate';
    }

    return (
        <section className={ cls }>
            { (playerID !== ctx.currentPlayer) ?
                <p>Your Turn</p>
                :
                <p>Opponent's Turn</p>
            }
        </section>
    );
}
